import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Col, Row, Container } from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { ProductContext } from '../../store/ProductContextProvider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
export default function ProductVariantEdit() {

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { getVariantById, updateProductVariant } = useContext(ProductContext);
  let { id } = useParams();
  let navigate = useNavigate();

  const [variantData, setVariantData] = useState({
    product_object_id: '',
    short_description: '',
    long_description: '',
    price: '',
    discount: '',
    tax: '',
    stock: '',
    sku: '',
    image: '',
    selling_price: '',
    secondary_images: []
  });


  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchVariantData();
  }, []);

  async function fetchVariantData() {
    try {
      const response = await getVariantById(id);
      console.log('CALING getVariantById >>>>>>>>>', response);

      if (response && response.data) {
        setVariantData({
          product_object_id: response.data.product_object_id || '',
          short_description: response.data.short_description || '',
          long_description: response.data.long_description || '',
          price: response.data.price || '',
          discount: response.data.discount || '',
          sku: response.data.sku || '',
          tax: response.data.tax || '',
          selling_price: response.data.selling_price || '',
          stock: response.data.stock || '',
          image: response.data.image || '',
          secondary_images: response.data.secondary_images || []
        });
        console.log('variantData>>>>>>>>>>>>>', variantData);

      }
    } catch (error) {
      console.error('Error fetching variant data:', error);
    }
  }

  // Handle input changes and automatically update selling price
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the corresponding field
    setVariantData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      // If price, discount, or tax changed, recalculate the selling price
      // If price, discount, or tax changed, recalculate the selling price
      if (name === 'price' || name === 'discount' || name === 'tax') {
        const price = name === 'price' ? value : updatedData.price;
        const discount = name === 'discount' ? value : updatedData.discount;
        const tax = name === 'tax' ? value : updatedData.tax;
        updatedData.selling_price = calculateSellingPrice(parseFloat(price), parseFloat(discount), parseFloat(tax));
      }
      return updatedData;
    });
  };

  // Function to calculate selling price based on price, discount, and tax
  const calculateSellingPrice = (price, discount, tax) => {
    // Apply discount and tax on the price
    const discountedPrice = price - (price * discount / 100);
    const taxedPrice = discountedPrice + (discountedPrice * tax / 100);
    return taxedPrice.toFixed(2);
  };



  // Handle image change for the primary image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVariantData({ ...variantData, image: file }); // Store the file object
    }
  };

  // Handle image change for secondary images
  const handleSecondaryImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setVariantData({ ...variantData, secondary_images: files }); // Store the file objects
  };


  const validateForm = () => {
    let formErrors = {};

    if (!variantData.price || isNaN(variantData.price)) formErrors.price = "Valid price is required";
    if (variantData.discount && (isNaN(variantData.discount) || variantData.discount < 0 || variantData.discount > 100)) {
      formErrors.discount = "Discount must be between 0 and 100";
    }
    if (!variantData.sku) formErrors.sku = "SKU is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true); // Show loader
    setIsButtonDisabled(true); // Disable button

    try {
      const formData = new FormData();
      console.log('variantData?>>>>>>>>>>', variantData);

      // Append all fields to FormData
      formData.append('product_object_id', variantData.product_object_id);
      formData.append('short_description', variantData.short_description);
      formData.append('long_description', variantData.long_description);
      formData.append('price', variantData.price);
      formData.append('discount', variantData.discount);
      formData.append('tax', variantData.tax);
      formData.append('stock', variantData.stock);
      formData.append('sku', variantData.sku);
      formData.append('selling_price', variantData.selling_price);

      // Append the primary image if available
      if (variantData.image) {
        formData.append('image', variantData.image);
      }

      // Append secondary images if available
      if (variantData.secondary_images.length > 0) {
        variantData.secondary_images.forEach((file, index) => {
          formData.append('secondary_images', file); // Append each file as a separate entry
        });
      }

      // Send the data to the API
      const response = await updateProductVariant(formData, id); // Assuming this handles FormData correctly
      console.log('response >>>>>>>>>>>>>>>>>>>>>>>>', response);

      if (response && response.status === true) {
        navigate(`/product-variant/${variantData.product_object_id}`);
      } else {
        alert('Error updating variant');
        setIsLoading(false); // Hide loader
        setIsButtonDisabled(false); // Enable button
      }
    } catch (error) {
      console.error('Error updating variant:', error);
      setIsLoading(false); // Hide loader
      setIsButtonDisabled(false); // Enable button
    }
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="page-content fade-in-up">
          <Container fluid>
            <div className="ibox">
              <div className="ibox-head d-flex justify-content-between align-items-center" style={{ minHeight: '122px' }}>
                <div>
                  <h3>Edit Product Variant & Manage your Inventories</h3>

                </div>
                <Link to={`/product-variant/${variantData.product_object_id}`} className="btn btn-secondary">
                  Back to Variant Detail
                </Link>
              </div>

              <div className="ibox-body">
                <Form onSubmit={handleSubmit}>

                  <Row className="mb-3">
                    <Col md={4}>
                      <Form.Group controlId="formPrice">
                        <Form.Label>Price<span className='text-danger'>*</span> (₹)</Form.Label>
                        <Form.Control
                          type="number"
                          name="price"
                          value={variantData.price}
                          onChange={handleInputChange}
                          isInvalid={!!errors.price}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.price}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formDiscount">
                        <Form.Label>Discount<span className='text-danger'>*</span> (%)</Form.Label>
                        <Form.Control
                          type="number"
                          name="discount"
                          value={variantData.discount}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Check if the value is numeric and length is less than or equal to 2
                            if (value.length <= 2 && /^[0-9]*$/.test(value)) {
                              handleInputChange(e); // Call the original input handler
                            }
                          }}
                          isInvalid={!!errors.discount}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.discount}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formTax">
                        <Form.Label>Tax<span className='text-danger'>*</span> (%)</Form.Label>
                        <Form.Control
                          type="text"
                          name="tax"
                          value={variantData.tax}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Check if the length of value is 2 or less
                            if (value.length <= 2) {
                              handleInputChange(e); // Call the original input handler
                            }
                          }}
                          isInvalid={!!errors.tax}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.tax}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={4}>
                      <Form.Group controlId="formSKU">
                        <Form.Label>SKU<span className='text-danger'>*</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="sku"
                          value={variantData.sku}
                          onChange={handleInputChange}
                          isInvalid={!!errors.sku}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.sku}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group controlId="formStock">
                        <Form.Label>Stock</Form.Label>
                        <Form.Control
                          type="text"
                          name="stock"
                          value={variantData.stock}
                          onChange={handleInputChange}
                          isInvalid={!!errors.stock}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.stock}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group controlId="formStock">
                        <Form.Label className='text-primary'>
                          <b>Final Selling Price</b>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="selling_price"
                          value={`₹ ${variantData.selling_price}`}
                          onChange={handleInputChange}
                          isInvalid={!!errors.selling_price}
                          disabled
                          style={{
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            backgroundColor: '#f7f7f7',
                            border: '2px solid #007bff',
                            borderRadius: '4px',
                            padding: '10px',
                            color: '#333',
                            boxShadow: '0 4px 8px rgba(0, 123, 255, 0.1)',
                            transition: 'border 0.3s ease, box-shadow 0.3s ease',
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.selling_price}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>


                  </Row>

                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Group controlId="formShortDescription">
                        <Form.Label>Short Description<span className='text-danger'>*</span></Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="short_description"
                          value={variantData.short_description}
                          onChange={handleInputChange}
                          isInvalid={!!errors.short_description}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.short_description}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={12}>
                      <Form.Group controlId="formLongDescription">
                        <Form.Label>Long Description<span className='text-danger'>*</span></Form.Label>
                        <ReactQuill
                          value={variantData.long_description}
                          onChange={(value) => setVariantData({ ...variantData, long_description: value })}
                          modules={{
                            toolbar: [
                              [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                              ['bold', 'italic', 'underline'],
                              [{ 'align': [] }],
                              ['link', 'image'],
                              ['clean'],
                            ],
                          }}
                          formats={[
                            'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline',
                            'align', 'link', 'image'
                          ]}
                          style={{ height: '200px' }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br /> <br />
                  <Row className="mb-3">
                    <Col md={6}>
                      <Form.Group controlId="formImage">
                        <Form.Label>Image<span className='text-danger'>*</span></Form.Label>
                        <Form.Control
                          type="file"
                          name="image"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                        {variantData.image && (
                          <div className="mt-3">
                            <img
                              src={variantData.image}
                              alt="Variant"
                              style={{ maxHeight: '150px', objectFit: 'cover' }}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formSecondaryImages">
                        <Form.Label>Secondary Images<span className='text-danger'>*</span></Form.Label>
                        <Form.Control
                          type="file"
                          name="secondary_images"
                          accept="image/*"
                          multiple
                          onChange={handleSecondaryImagesChange}
                        />
                        {variantData.secondary_images.length > 0 && (
                          <div className="mt-3 d-flex flex-wrap">
                            {variantData.secondary_images.map((image, i) => (
                              <img
                                key={i}
                                src={image}
                                alt={`Secondary ${i + 1}`}
                                style={{ maxHeight: '70px', maxWidth: '70px', objectFit: 'cover', marginRight: '10px' }}
                                className="rounded border"
                              />
                            ))}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Button type="submit" variant="primary" disabled={isButtonDisabled}>
                      {isLoading ? 'Variant Updating...' : 'Update Variant'}
                    </Button>
                  </Row>


                </Form>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
}
