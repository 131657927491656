import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const OrderContext = createContext()

async function getOrder(page, itemsPerPage, searchQuery) {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    let rawdata = await fetch(`${ADMIN_BASE_URL}/orders?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`, config2)
    // let rawdata = await fetch(`${ADMIN_BASE_URL}/banners?keyword=Summer%20Sale&status=1&page_no=1&limit=10`, config2)
    let result = await rawdata.json()

    if (result)
        return { result: "Done", data: result.data }
    else
        return { result: "Fail", message: "Internal Server Error" }
}

async function getOrderById(id) {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    let rawdata = await fetch(`${ADMIN_BASE_URL}/orders/${id}`, config2)
    // let rawdata = await fetch(`${ADMIN_BASE_URL}/banners?keyword=Summer%20Sale&status=1&page_no=1&limit=10`, config2)
    let result = await rawdata.json()
    return result;
}



async function updateOrderStatus(id, order_status) {
    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
        return { result: "Fail", message: "Token not found" };
    } else {
        let config = {
            headers: {
                "Content-Type": "application/json", // Updated for JSON format
                "Authorization": `Bearer ${token}` // Ensure correct header for token authentication
            }
        };

        try {
            // Sending updated category data with the corresponding _id
            let result = await axios.put(`${ADMIN_BASE_URL}/orders/order-status/${id}`, { order_status }, config);
            console.log('response result>>>>>', result);
            // Check if the response is successful
            return result
        } catch (error) {
            // Handle different error cases to provide detailed logging and messaging
            if (error.response) {
                // The request was made and the server responded with a status code outside the 2xx range
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);

            return { result: "Fail", message: "Error updating ShopbyColor order" };
        }
    }
}

async function cancelOrder(id, order_status) {
    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
        return { result: "Fail", message: "Token not found" };
    } else {
        let config = {
            headers: {
                "Content-Type": "application/json", // Updated for JSON format
                "Authorization": `Bearer ${token}` // Ensure correct header for token authentication
            }
        };

        try {
            // Sending updated category data with the corresponding _id
            let result = await axios.put(`${ADMIN_BASE_URL}/orders/${id}/cancel`, { order_status }, config);
            console.log('response result>>>>>', result);
            // Check if the response is successful
            return result
        } catch (error) {
            // Check if the error has a response object
            if (error.response) {
                // The request was made and the server responded with a status code
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);

                // Handle specific status code 400
                if (error.response.status === 400) {
                    const errorMessage = error.response.data.message || "Bad Request";
                    return { result: "Fail", message: errorMessage };
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);

            // Default error message for other cases
            return { result: "Fail", message: "Error Cancelling the order" };
        }

    }
}

async function updateOrderSort(order) {
    let token = localStorage.getItem("token");

    if (!token) {
        console.error('Token not found in localStorage');
        return { result: "Fail", message: "Token not found" };
    } else {
        let config = {
            headers: {
                "Content-Type": "application/json", // Updated for JSON format
                "Authorization": `Bearer ${token}` // Ensure correct header for token authentication
            }
        };

        try {
            // Sending updated category data with the corresponding _id
            let result = await axios.put(`${ADMIN_BASE_URL}/orders/drag/sort`, { order }, config);

            // Check if the response is successful
            if (result.status === 200 && result.data.success) {
                console.log('Success:', result);
                return { result: "Success", message: "Order order updated successfully" };
            } else {
                return { result: "Fail", message: result.data.message || "Internal Server Error" };
            }
        } catch (error) {
            // Handle different error cases to provide detailed logging and messaging
            if (error.response) {
                // The request was made and the server responded with a status code outside the 2xx range
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Error request:', error.request);
            } else {
                // Something happened in setting up the request
                console.error('Error message:', error.message);
            }
            console.error('Error config:', error.config);

            return { result: "Fail", message: "Error updating ShopbyColor order" };
        }
    }
}

export default function OrderContextProvider(props) {
    return <OrderContext.Provider value={
        {

            getOrder: getOrder,
            getOrderById: getOrderById,
            updateOrderSort: updateOrderSort,
            updateOrderStatus: updateOrderStatus,
            cancelOrder: cancelOrder
        }
    }>
        {props.children}
    </OrderContext.Provider>
}
