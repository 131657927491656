import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Form, Button, Col, Row, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill'; // Import Quill
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { BlogContext } from '../../store/BlogContextProvider';

export default function EditBlog() {
    const { id } = useParams(); // Get the blog ID from the URL
    const navigate = useNavigate();
    const { getBlogById, blogUpdate } = useContext(BlogContext); // Fetch and update blog API functions

    const [blog, setBlog] = useState({
        post_heading: '',
        post_url: '',
        category: '',
        tags: '',
        focus_keyword: '',
        seo_title: '',
        meta_keywords: '',
        meta_description: '',
        feature_image: null,
        post_images: [],
        short_description: '',
        long_description: '',
    });

    const [errors, setErrors] = useState({});
    const [imageError, setImageError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // Load the blog data when the component mounts
    useEffect(() => {
        const fetchBlogData = async () => {
            const response = await getBlogById(id); // Fetch blog by ID
            console.log('response:', response);

            if (response && response.status === 200) {
                const blogData = response.data;
                setBlog({
                    ...blogData
                });
            } else {
                alert('Failed to load blog data');
                navigate('/blogs');
            }
        };

        fetchBlogData();
    }, [id, getBlogById, navigate]);

    function handleChange(e) {
        const { name, value } = e.target;

        if (name === 'post_heading') {
            const postUrl = value
                .toLowerCase()
                .trim()
                .replace(/[^a-z0-9\s]/g, '')
                .replace(/\s+/g, '-');

            setBlog((prev) => ({
                ...prev,
                post_heading: value,
                post_url: postUrl,
                seo_title: value,
                focus_keyword: value,
            }));
        } else {
            setBlog((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    }

    function handleFileChange(e) {
        const { name, files } = e.target;
        if (files && files.length) {
            if (name === 'feature_image') {
                const file = files[0];
                if (!file.type.startsWith('image/')) {
                    setImageError('Feature image must be an image.');
                    return;
                }
                if (file.size > 2 * 1024 * 1024) {
                    setImageError('Feature image size should be less than 2MB.');
                    return;
                }
                setImageError('');
                setBlog((prev) => ({
                    ...prev,
                    [name]: file,
                }));
            } else if (name === 'post_images') {
                if (files.length > 3) {
                    setImageError('You can upload up to 3 images only.');
                    return;
                }

                const validImages = Array.from(files).filter(
                    (file) => file.type.startsWith('image/') && file.size <= 2 * 1024 * 1024
                );
                if (validImages.length !== files.length) {
                    setImageError('Some images were skipped (invalid type or size > 2MB).');
                }
                setBlog((prev) => ({
                    ...prev,
                    [name]: validImages,
                }));
            }
        }
    }

    function validateFields() {
        const tempErrors = {};
        if (!blog.post_heading) tempErrors.post_heading = 'Post Heading is required.';
        if (!blog.post_url) tempErrors.post_url = 'Post URL is required.';
        if (!blog.category) tempErrors.category = 'Category is required.';
        if (!blog.tags) tempErrors.tags = 'Tags are required.';
        if (!blog.focus_keyword) tempErrors.focus_keyword = 'Focus Keyword is required.';
        if (!blog.seo_title) tempErrors.seo_title = 'SEO Title is required.';
        if (!blog.meta_keywords) tempErrors.meta_keywords = 'Meta Keywords are required.';
        if (!blog.meta_description) tempErrors.meta_description = 'Meta Description is required.';
        if (!blog.short_description) tempErrors.short_description = 'Short Description is required.';
        if (!blog.long_description) tempErrors.long_description = 'Long Description is required.';

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (!validateFields()) return;

        setIsLoading(true);
        setIsButtonDisabled(true);

        const formData = new FormData();
        for (const key in blog) {
            if (key === 'post_images') {
                blog.post_images.forEach((image, index) => {
                    formData.append(`post_images`, image);
                });
            } else if (key === 'feature_image' && blog.feature_image) {
                formData.append(key, blog.feature_image);
            } else {
                formData.append(key, blog[key]);
            }
        }

        const response = await blogUpdate(id, formData); // Call update API
        if (response.result.status === 200) {
            localStorage.setItem('toast', 'Blog updated successfully.');
            navigate('/blogs');
        } else {
            alert(response.message);
        }

        setIsLoading(false);
        setIsButtonDisabled(false);
    }

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <Card className="shadow-lg mb-4">
                            <Card.Header className="bg-primary text-white text-center">
                                <h4 className="mb-0">EDIT BLOG</h4>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit}>
                                    {/* Blog Details */}
                                    <Card className="shadow-sm mb-4">
                                        <Card.Header className="bg-success text-white">Blog Details</Card.Header>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Post Heading <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="post_heading"
                                                            value={blog.post_heading}
                                                            onChange={handleChange}
                                                            placeholder="Enter post heading"
                                                        />
                                                        {errors.post_heading && (
                                                            <small className="text-danger">{errors.post_heading}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Post URL <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="post_url"
                                                            value={blog.post_url}
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Category <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="category"
                                                            value={blog.category}
                                                            onChange={handleChange}
                                                            placeholder="Enter category"
                                                        />
                                                        {errors.category && (
                                                            <small className="text-danger">{errors.category}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Tags <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="tags"
                                                            value={blog.tags}
                                                            onChange={handleChange}
                                                            placeholder="Enter tags"
                                                        />
                                                        {errors.tags && (
                                                            <small className="text-danger">{errors.tags}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    {/* Images */}
                                    <Card className="shadow-sm mb-4">
                                        <Card.Header className="bg-info text-white">Images</Card.Header>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                {/* Feature Image */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Feature Image</Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            name="feature_image"
                                                            onChange={handleFileChange}
                                                            accept="image/*"
                                                        />
                                                        {imageError && (
                                                            <small className="text-danger">{imageError}</small>
                                                        )}
                                                        {/* Display Current Feature Image */}
                                                        {blog.feature_image && (
                                                            <div className="mt-3">
                                                                <img
                                                                    src={blog.feature_image}
                                                                    alt="Feature"
                                                                    className="img-thumbnail"
                                                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                                />
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Col>

                                                {/* Additional Images */}
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Additional Images (Max 3)</Form.Label>
                                                        <Form.Control
                                                            type="file"
                                                            name="post_images"
                                                            multiple
                                                            onChange={handleFileChange}
                                                            accept="image/*"
                                                        />
                                                        {/* Display Current Additional Images */}
                                                        {blog.post_images?.length > 0 && (
                                                            <div className="mt-3 d-flex gap-2 flex-wrap">
                                                                {blog.post_images.map((imgUrl, index) => (
                                                                    <img
                                                                        key={index}
                                                                        src={imgUrl}
                                                                        alt={`Additional ${index + 1}`}
                                                                        className="img-thumbnail"
                                                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                                    />
                                                                ))}
                                                            </div>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>


                                    {/* Long Description */}
                                    <Card className="shadow-sm mb-4">
                                        <Card.Header className="bg-warning text-white">Content Details</Card.Header>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Short Description</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="short_description"
                                                            value={blog.short_description}
                                                            onChange={handleChange}
                                                            placeholder="Enter short description"
                                                        />
                                                        {errors.short_description && (
                                                            <small className="text-danger">{errors.short_description}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Long Description</Form.Label>
                                                        <ReactQuill
                                                            value={blog.long_description}
                                                            onChange={(value) => setBlog({ ...blog, long_description: value })}
                                                            placeholder="Write detailed blog content here..."
                                                            style={{ height: '300px' }}
                                                        />
                                                        {errors.long_description && (
                                                            <small className="text-danger">{errors.long_description}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    {/* SEO Details */}
                                    <Card className="shadow-sm mb-4">
                                        <Card.Header className="bg-secondary text-white">SEO Details</Card.Header>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>SEO Title</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="seo_title"
                                                            value={blog.seo_title}
                                                            onChange={handleChange}
                                                            placeholder="Enter SEO Title"
                                                        />
                                                        {errors.seo_title && (
                                                            <small className="text-danger">{errors.seo_title}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Focus Keyword</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="focus_keyword"
                                                            value={blog.focus_keyword}
                                                            onChange={handleChange}
                                                            placeholder="Enter Focus Keyword"
                                                        />
                                                        {errors.focus_keyword && (
                                                            <small className="text-danger">{errors.focus_keyword}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Meta Keywords</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="meta_keywords"
                                                            value={blog.meta_keywords}
                                                            onChange={handleChange}
                                                            placeholder="Enter Meta Keywords"
                                                        />
                                                        {errors.meta_keywords && (
                                                            <small className="text-danger">{errors.meta_keywords}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Meta Description</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="meta_description"
                                                            value={blog.meta_description}
                                                            onChange={handleChange}
                                                            placeholder="Enter meta description"
                                                        />
                                                        {errors.meta_description && (
                                                            <small className="text-danger">{errors.meta_description}</small>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <div className="text-center">
                                        <Button
                                            type="submit"
                                            className="btn-primary btn-lg"
                                            disabled={isButtonDisabled}
                                        >
                                            {isLoading ? 'Creating Blog...' : 'Create Blog'}
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

