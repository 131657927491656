import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { Modal, Button } from 'react-bootstrap';
import { ProductContext } from '../../store/ProductContextProvider';
import EditProductModal from '../../components/Product/EditProductModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/banner.css';

export default function ProductList() {
    const [product, setProduct] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalDocs] = useState(1);
    const [itemsPerPage] = useState(20);
    const [searchQuery, setSearchQuery] = useState('');
    const { getProducts, ProductStatus, deleteProduct, toggleFeatureProduct, updateProductOnPopup, updateProductMetaDetails } = useContext(ProductContext);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [confirmItem, setConfirmItem] = useState(null);

    // Fetch product list
    async function getAPIData(page) {
        try {
            let response = await getProducts(page, itemsPerPage, searchQuery);
            if (response.status) {
                setProduct(response.data.docs);
                setTotalPages(response.data.totalPages);
                setTotalDocs(response.data.totalDocs)
            } else {
                alert(response.message);
            }
        } catch (error) {
            console.log('calling from catch: ', error);
            handleErrorResponse(error);
        }
    }

    const handleErrorResponse = (error) => {
        if (error.response && error.response.status === 403) {
            setErrorMessage("You don't have access to view the product list.");
        } else if (error.response && error.response.status === 404) {
            setErrorMessage("The product list was not found.");
        } else {
            setErrorMessage("An unexpected error occurred.");
        }
        setShowErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    // Handle delete product
    const handleDelete = (item) => {
        setConfirmItem(item);
        setModalAction('delete');
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        if (confirmItem) {
            await deleteProduct(confirmItem._id);
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Product deleted successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Handle product status change
    const handleStatusChange = (item) => {
        setConfirmItem(item);
        setModalAction('status');
        setShowConfirmModal(true);
    };

    const confirmStatusChange = async () => {
        if (confirmItem) {
            const newStatus = confirmItem.is_active ? false : true;
            await ProductStatus(confirmItem._id, newStatus);
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success('Product status changed successfully', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Handle product feature toggle
    const handleFeatureChange = (item) => {
        setConfirmItem(item);
        setModalAction('feature');
        setShowConfirmModal(true);
    };

    const confirmFeatureChange = async () => {
        if (confirmItem) {
            const newStatus = confirmItem.is_feature ? false : true;
            await ProductStatus(confirmItem._id, { feature: newStatus });
            getAPIData(currentPage);
            setShowConfirmModal(false);
            toast.success(`Product ${newStatus ? 'featured' : 'unfeatured'} successfully`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Pagination
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Search
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getAPIData(currentPage);
    };

    const openEditModal = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedProduct(null);
    };

    const handleUpdateProduct = async (updatedProduct) => {
        // console.log('updatedProduct>>>>>>>>', updatedProduct);
        const response = await updateProductMetaDetails(updatedProduct);
        getAPIData(currentPage);
        toast.success('Product Updated successfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        // Implement this function if needed for updating the product
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getAPIData(currentPage);
        const toastr = localStorage.getItem('toast');
        if (toastr) {
            toast.success(toastr, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('toast');
        }
    }, [currentPage]);

    return (
        <>
            <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Product - List | Total Count ({totalCount})</h3>
                                </div>
                                <Link to="/product-add" className="btn btn-primary pull-right">Add New Record</Link>
                            </div>
                            <div className="ibox-body">
                                <div className="flexbox mb-4">
                                    <div className="input-group-icon input-group-icon-left mr-3">
                                        <span className="input-icon input-icon-right font-16"><i className="ti-search"></i></span>
                                        <form onSubmit={handleSearchSubmit} className="search-container">
                                            <input className="form-control form-control-rounded form-control-solid" id="key-search" type="text" placeholder="Search ..." value={searchQuery} onChange={handleSearchChange} />
                                        </form>
                                    </div>
                                </div>
                                <div className="table-responsive row">
                                    <table className="table table-bordered table-hover text-center" id="datatable">
                                        <thead className="thead-default thead-lg">
                                            <tr>
                                                <th style={{ width: '5%' }}>Sr No</th>
                                                <th style={{ width: '10%' }}>Image</th>
                                                <th style={{ width: '20%' }}>Product Details</th>
                                                <th style={{ width: '15%' }}>Category Details</th>
                                                <th style={{ width: '15%' }}>Date</th>
                                                <th style={{ width: '25%' }}>Description</th>
                                                <th className="no-sort" style={{ width: '5%' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.length > 0 ? product.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                    <td><img src={item.image} width="90px" height="70px" alt="Product" /></td>
                                                    <td>
                                                        <b>Name</b>: {item.product_name || ''}<br />
                                                        <b>ID</b>: {item.product_id || ''}<br />
                                                        <b>SKU</b>: {item.sku || ''}<br />

                                                    </td>
                                                    <td>
                                                        <b>Category</b>: {item.category?.name || ''}<br />
                                                        <Link to={`https://www.loopandcut.in/write-review/${item._id}`} className="badge badge-primary badge-pill" target='_blank' style={{ display: 'block', marginBottom: '5px', cursor: 'pointer', textDecoration: 'none' }}>
                                                            Write Review
                                                        </Link>
                                                        {/* <b>Sub Category</b>: {item.sub_category?.name || ''} */}
                                                    </td>
                                                    <td>
                                                        <b>Added On</b>: {item.createdAt ? new Date(item.createdAt).toLocaleDateString('en-GB') : ''}
                                                        <br /> <b>Updated On </b>: {item.updatedAt ? new Date(item.createdAt).toLocaleDateString('en-GB') : ''}<br />

                                                    </td>
                                                    <td>{item.short_description.length > 150 ? `${item.short_description.substring(0, 150)}...` : item.short_description}
                                                        <button
                                                            className="btn btn-sm btn-outline-primary text-center mt-2"
                                                            style={{
                                                                textAlign: 'center',
                                                                display: 'block',
                                                                width: 'fit-content',
                                                                cursor: 'pointer',
                                                                borderRadius: '5px',
                                                            }}
                                                            onClick={() => openEditModal(item)}
                                                        >
                                                            Edit Snippet
                                                        </button>
                                                    </td>
                                                    <td className="text-center">
                                                        {/* <span className="badge bg-info badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => window.location.href = `/product-variant-add/${item._id}`}>
                                                            Add Variants
                                                        </span> */}
                                                        <span className="badge badge-success badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => window.location.href = `/product-variant/${item._id}`}>
                                                            View Variants
                                                        </span>


                                                        {/* <Link to={`/product-variant/${item._id}`} className="badge badge-success badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer', textDecoration: 'none' }}>
                                                            View Variants
                                                        </Link> */}


                                                        <Link to={`/edit-product/${item._id}`} className="badge badge-primary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer', textDecoration: 'none' }}>
                                                            Edit Product
                                                        </Link>
                                                        {/* <span
                                                            className="badge badge-primary badge-pill"
                                                            style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }}
                                                            onClick={() => openEditModal(item)}  // Updated to handle the click
                                                        >
                                                            Edit Product
                                                        </span> */}
                                                        {item.is_active ? (
                                                            <span className="badge bg-warning text-dark badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                Deactivate Product
                                                            </span>
                                                        ) : (
                                                            <span className="badge bg-secondary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleStatusChange(item)}>
                                                                Activate Product
                                                            </span>
                                                        )}
                                                        {item.is_feature ? (
                                                            <span className="badge bg-secondary badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleFeatureChange(item)}>
                                                                Remove Feature
                                                            </span>
                                                        ) : (
                                                            <span className="badge badge-info badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleFeatureChange(item)}>
                                                                Add Feature
                                                            </span>
                                                        )}
                                                        <span className="badge badge-danger badge-pill" style={{ display: 'block', marginBottom: '5px', cursor: 'pointer' }} onClick={() => handleDelete(item)}>
                                                            Delete Product
                                                        </span>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={7}>No Records Found..</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="pagination-info">{`Page ${currentPage} of ${totalPages}`}</span>
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            {/* Modal for Confirmations */}
            <div className={`modal fade ${showConfirmModal ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalAction === 'delete' ? 'Confirm Product Deletion' : modalAction === 'feature' ? 'Confirm Feature Toggle' : 'Confirm Status Update'}</h5>
                            <button type="button" className="btn-close" onClick={() => setShowConfirmModal(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{modalAction === 'delete' ? 'Are you sure you want to delete this product?' : modalAction === 'feature' ? 'Are you sure you want to toggle the feature status?' : 'Are you sure you want to update the status?'}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmModal(false)}>
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary" onClick={modalAction === 'delete' ? confirmDelete : modalAction === 'feature' ? confirmFeatureChange : confirmStatusChange}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {selectedProduct && (
                <EditProductModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    product={selectedProduct}
                    handleUpdate={handleUpdateProduct}
                />
            )}
        </>
    );
}
