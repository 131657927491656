import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { PromocodeContext } from '../../store/PromocodeContextProvider';
import { ToastContainer, toast } from 'react-toastify';

export default function EditPromocode() {
  const { PromocodeUpdate, getPromocodeById } = useContext(PromocodeContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [formData, setFormData] = useState({
    title: '',
    promocode: '',
    discount_type: '',
    discount_value: '',
    usageLimit: '',
    expiry_date: '',
    max_discounted_amount_upto: '',
    min_amount_to_applicable: '',
    is_applicable_for_all_products: '',
    description: ''
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  var { _id } = useParams()

  const validate = () => {
    let tempErrors = {};
    tempErrors.title = formData.title ? "" : "title is required.";
    tempErrors.promocode = formData.promocode ? "" : "promocode is required.";
    tempErrors.expiry_date = formData.expiry_date ? "" : "expiry_date is required.";
    tempErrors.description = formData.description ? "" : "description is required.";
    tempErrors.is_applicable_for_all_products = formData.is_applicable_for_all_products ? "" : "is_applicable_for_all_products is required.";


    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  // const handleChange = (e) => {
  //     setFormData({
  //         ...formData,
  //         [e.target.name]: e.target.value
  //     });
  // };



  function handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    setFormData((oldData) => {
      return {
        ...oldData,
        [name]: value
      }
    });
  }

  const handleSubmit = async (e) => {



    e.preventDefault();
    if (validate()) {

      setIsLoading(true); // Show loader
      setIsButtonDisabled(true); // Disable button
      try {

        var item = {
          title: formData.title,
          promocode: formData.promocode,
          discount_type: formData.discount_type,
          discount_value: formData.discount_value,
          usageLimit: formData.usageLimit,
          expiry_date: formData.expiry_date,
          max_discounted_amount_upto: formData.max_discounted_amount_upto,
          min_amount_to_applicable: formData.min_amount_to_applicable,
          description: formData.description,
          is_applicable_for_all_products: formData.is_applicable_for_all_products

        }


        const response = await PromocodeUpdate(item, _id);
        console.log(response)

        toast.success('Promocode added successfully!');
        navigate('/promocode');

      } catch (error) {
        toast.error('An error occurred while adding the Sub Admin.');
        setIsLoading(false); // Hide loader
        setIsButtonDisabled(false); // Enable button
      }
    } else {
      toast.error('Please fix the errors in the form.');
      setIsLoading(false); // Hide loader
      setIsButtonDisabled(false); // Enable button
    }
  };




  async function getAPIData() {
    if (!_id) {
      console.error('ID is undefined or null');
      return;
    }

    var item = {
      _id: _id
    };
    // console.log(item);

    try {
      var response = await getPromocodeById(item);
      console.log(response);
      setFormData(response.data.data);
    } catch (error) {
      console.error('Error fetching Promocode:', error);
    }
  }




  useEffect(() => {
    getAPIData()
  }, [])



  return (
    <>
      <div className="page-wrapper">
        <Header />
        <ToastContainer />
        <Sidebar />
        <div className="content-wrapper">
          <div className="page-content fade-in-up">
            <div className="ibox">
              <div className="ibox-head">
                <div className="ibox-title">
                  <h3>Update Promocode  </h3>
                </div>
                <Link to="/promocode" className="btn btn-secondary pull-right">
                  Back
                </Link>
              </div>
              <div className="ibox-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          required
                        />
                        {errors.title && <span className="text-danger">{errors.title}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Promocode</label>
                        <input
                          type="text"
                          className="form-control"
                          name="promocode"
                          value={formData.promocode}
                          onChange={handleChange}
                          required
                        />
                        {errors.promocode && <span className="text-danger">{errors.promocode}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Discount Type</label>
                        <select
                          className="form-control"
                          name="discount_type"
                          value={formData.discount_type || ""} // Ensure value is handled for initial edit case
                          onChange={handleChange}
                          required
                        >

                          <option value="Percentage">Percentage</option>
                          <option value="Flat">Flat</option>
                        </select>
                        {errors.discount_type && <span className="text-danger">{errors.discount_type}</span>}
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Discount Value</label>
                        <input
                          type="text"
                          className="form-control"
                          name="discount_value"
                          value={formData.discount_value}
                          onChange={handleChange}
                          required
                        />
                        {errors.discount_value && <span className="text-danger">{errors.discount_value}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Usage Limit</label>
                        <input
                          type="text"
                          className="form-control"
                          name="usageLimit"
                          value={formData.usageLimit}
                          onChange={handleChange}
                          required
                        />
                        {errors.usageLimit && <span className="text-danger">{errors.usageLimit}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Expiry date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="expiry_date"
                          value={formData.expiry_date}
                          onChange={handleChange}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Max Discounted Amount</label>
                        <input
                          type="text"
                          className="form-control"
                          name="max_discounted_amount_upto"
                          value={formData.max_discounted_amount_upto}
                          onChange={handleChange}

                        />
                        {errors.max_discounted_amount_upto && <span className="text-danger">{errors.max_discounted_amount_upto}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Min Amount Applicable</label>
                        <input
                          type="text"
                          className="form-control"
                          name="min_amount_to_applicable"
                          value={formData.min_amount_to_applicable}
                          onChange={handleChange}

                        />
                      </div>
                    </div>
                  </div>



                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Is Applicable For All Products</label>
                      <select
                        className="form-control"
                        name="is_applicable_for_all_products"
                        value={formData.is_applicable_for_all_products || ""} // Default to "no"
                        onChange={handleChange}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="description" className="control-label">Description<span className="text-danger">*</span></label>
                        <textarea id="description" className="form-control" rows={5} name="description" value={formData.description} onChange={handleChange} maxLength="100"></textarea>
                        {errors.description && <small className="text-danger">{errors.description}</small>}
                      </div>
                    </div>

                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary  mt-2" disabled={isButtonDisabled}>
                      {isLoading ? 'Promocode Updating...' : 'Update Promocode'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
