import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/permissions.css";
import { useParams, useNavigate, Link } from 'react-router-dom';
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from 'react-toastify';
import { SubadminContext } from "../../store/SubadminContextProvider";
import 'react-toastify/dist/ReactToastify.css';

export default function Permissions() {
    const [modules, setModules] = useState([]); // All modules from permissions API
    const [permissions, setPermissions] = useState({}); // Subadmin-specific permissions
    const [customerDetails, setCustomerDetails] = useState({
        name: "",
        email: "",
        country_code: "",
        phone: "",
        is_active: null,
    }); // Define state with keys
    const [searchTerm, setSearchTerm] = useState("");
    const { getpermissions, getSubAdminById, SubAdminUpdatePermission } = useContext(SubadminContext);
    const { _id } = useParams(); // Subadmin ID
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    // Fetch all permissions and subadmin-specific permissions
    const fetchData = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        try {
            const obj = { _id };
            let [permissionsResponse, subadminResponse] = await Promise.all([
                getpermissions(),
                getSubAdminById(obj),
            ]);

            console.log("permissionsResponse data:", permissionsResponse);
            console.log("subadminResponse data:", subadminResponse);
            subadminResponse = subadminResponse.data;
            if (permissionsResponse.data && permissionsResponse.data.permissionsData && subadminResponse.data) {
                setModules(permissionsResponse.data.permissionsData);
                setCustomerDetails({
                    name: subadminResponse.data.name || "",
                    email: subadminResponse.data.email || "",
                    country_code: subadminResponse.data.country_code || "",
                    phone: subadminResponse.data.phone || "",
                    is_active: subadminResponse.data.is_active || false,
                });
                initializePermissions(permissionsResponse.data.permissionsData, subadminResponse.data.user_permissions);
            } else {
                toast.error('Failed to fetch permissions data', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("An error occurred while fetching data");
        }
    };

    // Initialize permissions with subadmin-specific data
    const initializePermissions = (modulesData, userPermissions = {}) => {
        const initialPermissions = {};
        modulesData.forEach((module) => {
            // Default to an empty array if the key doesn't exist in userPermissions
            initialPermissions[module.module_slug] = userPermissions[module.module_slug] || [];
        });

        setPermissions(initialPermissions);
    };

    // Toggle permission
    const togglePermission = (moduleSlug, permissionSlug) => {
        setPermissions((prevPermissions) => {
            const updatedPermissions = { ...prevPermissions };

            if (updatedPermissions[moduleSlug].includes(permissionSlug)) {
                // Remove permission if it exists
                updatedPermissions[moduleSlug] = updatedPermissions[moduleSlug].filter((slug) => slug !== permissionSlug);
            } else {
                // Add permission if it does not exist
                updatedPermissions[moduleSlug].push(permissionSlug);
            }

            return updatedPermissions;
        });
    };

    // Save permissions
    const savePermissions = async () => {
        try {
            setSaving(true);
            const response = await SubAdminUpdatePermission(permissions, _id);
            if (response.result.data.status == 200) {
                // alert("Permissions saved successfully!");
                toast.success('Permissions saved successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setTimeout(() => {
                    navigate('/subadmin-list');
                }, 2000);
            } else {
                toast.error('Failed to save permissions data', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error saving permissions:", error);
            alert("Failed to save permissions");
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredModules = modules.filter((module) =>
        module.module_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="permissions-container">

                            <h2 className="permissions-title">

                                Manage Permissions for Sub Admin   <Link to="/subadmin-list" className="btn btn-secondary pull-right" > &nbsp;Back to List</Link></h2>
                            {/* <Link to="/subcategory" className="btn btn-secondary pull-right">Back</Link> */}
                            {/* Display Customer Details */}
                            {customerDetails.name && (
                                <div className="customer-details">
                                    <p><strong>Name:</strong> {customerDetails.name}</p>
                                    <p><strong>Email:</strong> {customerDetails.email}</p>
                                    <p><strong>Phone:</strong> {customerDetails.country_code} {customerDetails.phone}</p>
                                    <p><strong>Status:</strong> {customerDetails.is_active ? "Active" : "Inactive"}</p>
                                </div>
                            )}

                            <input
                                type="text"
                                className="permissions-search"
                                placeholder="Search Modules..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <div className="permissions-grid">
                                {filteredModules.map((module) => (
                                    <div key={module.module_slug} className="permissions-card">
                                        <h3 className="module-name">{module.module_name}</h3>
                                        <div className="permission-toggles">
                                            {module.permissions.map((perm) => (
                                                <div key={perm.slug} className="permission-toggle">
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={permissions[module.module_slug]?.includes(perm.slug) || false}
                                                            onChange={() => togglePermission(module.module_slug, perm.slug)}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                    <span className="permission-label">{perm.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button
                                className={`save-button ${saving ? "saving" : ""}`}
                                onClick={savePermissions}
                                disabled={saving}
                            >
                                {saving ? "Saving..." : "Save Permissions"}
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
