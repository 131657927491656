import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { OrderContext } from "../../store/OrderContextProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaBox, FaUser, FaShippingFast, FaMoneyCheckAlt, FaBuilding, FaTag } from "react-icons/fa";

export default function ManageOrder() {
    const { id } = useParams(); // Retrieve order ID from URL
    const [orderDetails, setOrderDetails] = useState({
        order_number: "",
        invoice_number: "",
        customer: {
            firstname: "",
            lastname: "",
            email: "",
            mobile_no: "",
        },
        vendor: {
            business_details: {
                business_name: "",
                gst_number: "",
            },
            address: {
                street: "",
                city: "",
                state: "",
                postal_code: "",
            },
        },
        shipping_address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
        },
        billing_address: {
            street: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
        },
        amount_info: {},
        order_details: [],
        order_status: "",
    });

    const [loading, setLoading] = useState(true);
    const [orderStatus, setOrderStatus] = useState("");
    const { getOrderById, updateOrderStatus, cancelOrder } = useContext(OrderContext);

    // Fetch order details
    useEffect(() => {
        fetchOrderDetails();
    }, [id, getOrderById]);

    async function fetchOrderDetails() {
        setLoading(true); // Ensure loading state is managed
        try {
            const response = await getOrderById(id);
            if (response?.status === 200 && response?.data) {
                setOrderDetails(response.data);
                setOrderStatus(response.data.order_status);
            } else {
                toast.error(response?.message || "Failed to fetch order details");
            }
        } catch (error) {
            toast.error("Error fetching order details");
        } finally {
            setLoading(false);
        }
    }
    // Handle cancel order
    const handleCancelOrder = async (id) => {
        const confirmation = window.confirm("Are you sure you want to cancel this order?");
        if (!confirmation) {
            return; // Exit if the user cancels the confirmation
        }
        const orderStatus = "cancelled";
        try {
            const response = await cancelOrder(id, orderStatus);
            if (response.status === 200) {
                toast.success("Order status updated successfully");
                // Refetch order details after successful cancellation
                fetchOrderDetails();
            } else {
                toast.error(response.message || "Failed to update order status");
            }
        } catch (error) {
            toast.error("Error cancelling order");
        }
    };

    // Handle order status update
    const handleStatusChange = (e) => {
        setOrderStatus(e.target.value);
    };
    const handleSaveChanges = async () => {
        const response = await updateOrderStatus(id, orderStatus);
        if (response.status === 200) {
            toast.success("Order status updated successfully");
            // fetchOrderDetails();
        } else {
            toast.error(response.message);
        }
    };

    const ViewInvoice = (_id) => {
        if (!_id) {
            alert("Order number not available!");
            return;
        }
        // Replace the URL with your actual invoice download API endpoint
        const invoiceUrl = `/view-invoice/${_id}`;
        window.open(invoiceUrl, "_blank");
    };


    if (loading) {
        return <div className="text-center py-5">Loading...</div>;
    }

    const orderStatusOptions = [
        "pending",
        "confirmed",
        "processing",
        // "cancelled",
        "shipped",
        "out_for_delivery",
        "delivered",
        "returned",
        "payment_not_completed",
        "refund_initiated",
        "refund_completed",
        "failed",
    ];
    const statusColors = {
        pending: "badge-warning",
        confirmed: "badge-info",
        processing: "badge-primary",
        cancelled: "badge-danger",
        shipped: "badge-secondary",
        out_for_delivery: "badge-light",
        delivered: "badge-success",
        returned: "badge-dark",
        payment_not_completed: "badge-warning",
        refund_initiated: "badge-info",
        refund_completed: "badge-success",
        failed: "badge-danger",
    };


    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container py-4">
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
                            <div className="d-flex align-items-center">
                                <h2 className="text-primary mb-0 d-flex align-items-center">
                                    <FaBox className="me-2" />
                                    Manage Order ({orderDetails?.order_number || "N/A"}) -
                                    {orderDetails?.order_status ? (
                                        <span
                                            className={`badge ms-2 ${statusColors[orderDetails.order_status]}`}
                                            style={{
                                                fontSize: "1rem",
                                                padding: "0.5rem 0.75rem",
                                                borderRadius: "12px",
                                            }}
                                        >
                                            {orderDetails.order_status
                                                .replace(/_/g, " ")
                                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                                        </span>
                                    ) : (
                                        <span className="badge badge-secondary ms-2">N/A</span>
                                    )}
                                </h2>
                            </div>
                            <div className="d-flex flex-column flex-md-row mt-3 mt-md-0">

                                <button
                                    onClick={() => ViewInvoice(orderDetails?._id)}
                                    className="btn btn-success"
                                    style={{
                                        fontSize: "1rem",
                                        padding: "0.5rem 1rem",
                                        borderRadius: "8px",
                                        textTransform: "uppercase",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <i className="bi bi-download me-2"></i> View Invoice
                                </button>
                                &nbsp; &nbsp; &nbsp;
                                <Link
                                    to="/orders"
                                    className="btn btn-secondary me-2"
                                    style={{
                                        fontSize: "1rem",
                                        padding: "0.5rem 1rem",
                                        borderRadius: "8px",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    Back to Orders List
                                </Link>
                            </div>
                        </div>




                        {/* Order Summary */}
                        <div className="card mb-4">
                            <div className="card-header bg-primary text-white">
                                <h5 className="mb-0"><FaMoneyCheckAlt className="me-2" /> Order Details</h5>
                            </div>
                            <div className="card-body">
                                <p><strong>Order Number:</strong> {orderDetails?.order_number || "N/A"}</p>
                                <p>
                                    <strong>Order Status:</strong>{" "}
                                    {orderDetails?.order_status
                                        ? orderDetails.order_status
                                            .replace(/_/g, " ") // Replace underscores with spaces
                                            .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
                                        : "N/A"}
                                </p>

                                <p>
                                    <strong>Order Created: </strong>
                                    {orderDetails.createdAt
                                        ? new Date(orderDetails.createdAt).toLocaleString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })
                                        : 'N/A'}
                                </p>
                                <p>
                                    <strong>Order Updated: </strong>
                                    {orderDetails.updatedAt
                                        ? new Date(orderDetails.updatedAt).toLocaleString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })
                                        : 'N/A'}
                                </p>

                                <div className="d-flex align-items-center">
                                    <strong className="me-2">Order Status:</strong>
                                    <select
                                        value={orderStatus}
                                        onChange={handleStatusChange}
                                        className="form-select w-auto"
                                        disabled={orderDetails.order_status === "cancelled"} // Disable if the status is 'cancelled'
                                    >
                                        {orderStatusOptions.map((status) => (
                                            <option key={status} value={status}>
                                                {status
                                                    .replace(/_/g, " ") // Replace underscores with spaces
                                                    .replace(/\b\w/g, (char) => char.toUpperCase())} {/* Capitalize the first letter of each word */}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        className="btn btn-success ms-3"
                                        onClick={handleSaveChanges}
                                        disabled={orderDetails.order_status === "cancelled"} // Disable if the status is 'cancelled'
                                    >
                                        Save Changes
                                    </button>
                                </div>

                            </div>
                        </div>
                        {/* Order Items */}
                        <div className="card mb-4">
                            <div className="card-header bg-dark text-white">
                                <h5 className="mb-0"><FaBox className="me-2" /> Order Items</h5>
                            </div>
                            <div className="card-body">
                                <table className="table table-bordered">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>Product</th>
                                            <th>SKU</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetails?.order_details.map((item) => (
                                            <tr key={item._id}>
                                                <td>
                                                    <img
                                                        src={item.image}
                                                        alt={item.product_name}
                                                        className="img-thumbnail me-2"
                                                        style={{ width: "50px", height: "50px" }}
                                                    />
                                                    {item.product_name}
                                                </td>
                                                <td>{item.sku}</td>
                                                <td>₹{item.selling_price.toLocaleString('en-IN')}</td>
                                                <td>{item.quantity}</td>
                                                <td>₹{Number(item.selling_price * item.quantity).toLocaleString('en-IN')}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* Amount Info Section */}
                        {orderDetails?.amount_info && (
                            <div className="card mb-4">
                                <div className="card-header bg-warning text-white">
                                    <h5 className="mb-0">
                                        <FaMoneyCheckAlt className="me-2" /> Amount Information
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <p>
                                        <strong>Total Order Amount:</strong> ₹{new Intl.NumberFormat('en-IN').format(orderDetails.amount_info.total_order_amount)}
                                    </p>
                                    <p>
                                        <strong>Discounted Amount:</strong> ₹{new Intl.NumberFormat('en-IN').format(orderDetails.amount_info.discounted_amount)}
                                    </p>
                                    <p>
                                        <strong>Amount Paid by Customer:</strong> <b>₹{new Intl.NumberFormat('en-IN').format(orderDetails.amount_info.total_amount_paid_by_customer)}</b>
                                    </p>
                                    {/* <p>
                                        <strong>Refund Amount (if any):</strong> ₹{new Intl.NumberFormat('en-IN').format(orderDetails.amount_info.refund_amount || 0)}
                                    </p> */}
                                </div>
                            </div>
                        )}



                        {/* Customer Information */}
                        <div className="card mb-4">
                            <div className="card-header bg-secondary text-white">
                                <h5 className="mb-0"><FaUser className="me-2" /> Customer Information</h5>
                            </div>
                            <div className="card-body">
                                <p><strong>Name:</strong> {`${orderDetails?.customer?.firstname} ${orderDetails?.customer?.lastname}`}</p>
                                <p><strong>Email:</strong> {orderDetails?.customer?.email || "N/A"}</p>
                                <p><strong>Mobile:</strong> {orderDetails?.customer?.mobile_no || "N/A"}</p>
                            </div>
                        </div>
                        {/* Shipping and Billing Address */}
                        <div className="accordion" id="addressAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="shippingAddressHeading">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#shippingAddress"
                                    >
                                        Shipping Address ({orderDetails?.shipping_address?.title})
                                    </button>
                                </h2>
                                <div
                                    id="shippingAddress"
                                    className="accordion-collapse collapse show"
                                    data-bs-parent="#addressAccordion"
                                >
                                    <div className="accordion-body">
                                        {`${orderDetails?.shipping_address?.landmark}, ${orderDetails?.shipping_address?.street}, ${orderDetails?.shipping_address?.city}, ${orderDetails?.shipping_address?.state}, ${orderDetails?.shipping_address?.country}, ${orderDetails?.shipping_address?.pincode}`}
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="billingAddressHeading">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#billingAddress"
                                    >
                                        Billing Address ({orderDetails?.shipping_address?.title})
                                    </button>
                                </h2>
                                <div
                                    id="billingAddress"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#addressAccordion"
                                >
                                    <div className="accordion-body">
                                        {`${orderDetails?.shipping_address?.landmark}, ${orderDetails?.billing_address?.street}, ${orderDetails?.billing_address?.city}, ${orderDetails?.billing_address?.state}, ${orderDetails?.billing_address?.country}, ${orderDetails?.billing_address?.pincode}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />

                        {/* Vendor Information */}
                        <div className="card mb-4">
                            <div className="card-header bg-info text-white">
                                <h5 className="mb-0"><FaBuilding className="me-2" /> Vendor Information</h5>
                            </div>
                            <div className="card-body">
                                <p><strong>Business Name:</strong> {orderDetails?.vendor?.firstname || "N/A"} {orderDetails?.vendor?.lastname || "N/A"}</p>
                                <p><strong>GST Number:</strong> {orderDetails?.vendor?.business_details?.gst_number || "N/A"}</p>
                                <p>
                                    <strong>Address:</strong>{" "}
                                    {`${orderDetails?.vendor?.address?.street}, ${orderDetails?.vendor?.address?.city}, ${orderDetails?.vendor?.address?.state}, ${orderDetails?.vendor?.address?.postal_code}`}
                                </p>
                            </div>
                        </div>



                        {/* Promo Code Section */}
                        {orderDetails?.promocode_info && (
                            <div className="card mb-4">
                                <div className="card-header bg-warning text-white">
                                    <h5 className="mb-0"><FaTag className="me-2" /> Promo Code</h5>
                                </div>
                                <div className="card-body">
                                    <p><strong>Title:</strong> {orderDetails.promocode_info.title}</p>
                                    <p><strong>Promo Code:</strong> {orderDetails.promocode_info.promocode}</p>
                                    <p><strong>Discount Type:</strong> {orderDetails.promocode_info.discount_type}</p>
                                    <p><strong>Discount Value:</strong> {orderDetails.promocode_info.discount_value}%</p>

                                </div>
                            </div>
                        )}
                        {/* Cancel Order Button */}
                        {orderDetails.createdAt &&
                            orderDetails.order_status !== "cancelled" && // Check if the order status is not 'cancelled'
                            new Date() - new Date(orderDetails.createdAt) <= 24 * 60 * 60 * 1000 && ( // Check if within 24 hours
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleCancelOrder(orderDetails._id)} // Call the cancel order function
                                    style={{
                                        display: "block",
                                        marginTop: "5px",
                                        borderRadius: "100px"
                                    }}
                                >
                                    Cancel Order
                                </button>
                            )}

                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
