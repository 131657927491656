import React, { createContext } from 'react'
import axios from 'axios';
import { ADMIN_BASE_URL } from '../utils/config'
export const ProductContext = createContext()


//get all products list
async function getProducts(page, itemsPerPage, searchQuery) {
    let config = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    let url = `${ADMIN_BASE_URL}/products?keyword=${searchQuery}&status=&page_no=${page}&limit=${itemsPerPage}`;
    let response = await axios.get(url, config)
    // console.log("response : ", response)
    if (response.status == 200) {
        return { status: true, data: response.data.data };
    } else {
        return { status: false, message: "Internal Server Error" };
    }
}
//get    getSkuExists
async function getSkuExists(sku) {
    let config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
    };

    try {
        // Making a POST request with the sku_code in the body
        const response = await axios.post(
            `${ADMIN_BASE_URL}/products/is-sku-exists`,
            { sku_code: sku }, // Sending the sku code in the body
            config
        );

        // console.log("response : ", response);

        if (response.status === 200) {
            // SKU does not exist, so it can be used
            return { status: true, data: response.data.data };
        } else {
            return { status: false, message: "Internal Server Error" };
        }
    } catch (error) {
        // console.error("Error in getSkuExists:", error);
        // Handle the case when the SKU is already in use (400 status)
        if (error.response && error.response.status === 400) {
            return { status: false, message: "SKU already in use" }; // SKU exists
        }
        return { status: false, message: error.message || "Internal Server Error" };
    }
}

//get getURLExists
async function getURLExists(url) {
    let config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
    };
    // console.log('calling in getURLExists contextb :', url);

    try {
        // Making a POST request with the sku_code in the body
        const response = await axios.post(
            `${ADMIN_BASE_URL}/products/is-url-exists`,
            { product_url: url }, // Sending the sku code in the body
            config
        );

        // console.log("response : ", response);

        if (response.status === 200) {
            // SKU does not exist, so it can be used
            return { status: true, data: response.data.data };
        } else {
            return { status: false, message: "Internal Server Error" };
        }
    } catch (error) {
        // console.error("Error in getSkuExists:", error);
        // Handle the case when the SKU is already in use (400 status)
        if (error.response && error.response.status === 400) {
            return { status: false, message: "SKU already in use" }; // SKU exists
        }
        return { status: false, message: error.message || "Internal Server Error" };
    }
}


//add new product
const addProduct = async (formDataToSubmit) => {
    console.log('formDataToSubmit >>>>>>>>>>>>> formDataToSubmit', formDataToSubmit)
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure that Axios recognizes this as a file upload
                'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include token if required for authentication
            },
        };

        // Make the POST request to add the product
        const response = await axios.post(`${ADMIN_BASE_URL}/products`, formDataToSubmit, config);

        // Check response status
        if (response.status === 200) {
            return { result: { status: 200, data: response.data } };
        } else {
            return { result: { status: response.status, message: 'Failed to add product' } };
        }
    } catch (error) {
        console.error('Error adding product:', error);
        return { result: error };
    }
};


const updateProduct = async (formDataToSubmit, _id) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data', // Ensure that Axios recognizes this as a file upload
                'Authorization': `Bearer ${localStorage.getItem('token')}`, // Include token if required for authentication
            },
        };

        // Make the POST request to add the product
        const response = await axios.put(`${ADMIN_BASE_URL}/products/${_id}`, formDataToSubmit, config);

        // Check response status
        if (response.status === 200) {
            return { result: { status: 200, data: response.data } };
        } else {
            return { result: { status: response.status, message: 'Failed to add product' } };
        }
    } catch (error) {
        // console.error('Error adding product:', error);
        return { result: { status: 500, message: 'Internal Server Error' } };
    }
};

async function updateProductOnPopup(data) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    let result = await axios.put(`${ADMIN_BASE_URL}/products/products_by_popup/${data._id}`, data, config3)
    // let result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Product is Deleted" }
}
async function updateProductMetaDetails(data) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let result = await axios.put(`${ADMIN_BASE_URL}/products/update_meta_details/${data._id}`, data, config3)
    // let result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Product Updated" }
}


// get single product by id
async function getProductById(id) {
    let config3 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await axios.get(`${ADMIN_BASE_URL}/products/${id}`, config3);
        // console.log("response : ", response)
        if (response.status == 200) {
            return { status: true, data: response.data.data };
        } else {
            return { status: false, message: "Internal Server Error" };
        }
    } catch (error) {
        return { status: false, message: error.message };
    }
}


async function getAttributesList() {
    let config2 = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    let rawdata = await fetch(`${ADMIN_BASE_URL}/attributes?keyword=&status=&page_no=1&limit=50`, config2)
    // let rawdata = await fetch(`${ADMIN_BASE_URL}/attributes?keyword=Summer%20Sale&status=1&page_no=1&limit=10`, config2)
    let result = await rawdata.json()
    console.log('result >>>>>>>>>>>>>>>>>>>>>', result)
    if (result) {
        return { result: "Done", data: result.data }
    }
    else {
        return { result: "Fail", message: "Internal Server Error" }
    }
}

// get product list by product id
async function getProductVariants(id) {
    let config = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };

    try {
        let response = await axios.get(`${ADMIN_BASE_URL}/products/variants-list/${id}`, config);
        // console.log("Variant response : ", response)
        if (response.status == 200) {
            return { status: true, data: response.data.data };
        } else {
            return { status: false, message: "Internal Server Error" };
        }
    } catch (error) {
        return { status: false, message: error.message };
    }
}

// get single variant
async function getVariantById(id) {
    let config = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    try {
        let response = await axios.get(`${ADMIN_BASE_URL}/products/variant/${id}`, config);
        if (response.status == 200) {
            return { status: true, data: response.data.data };
        } else {
            return { status: false, message: "Internal Server Error" };
        }
    } catch (error) {
        return { status: false, message: error.message };
    }
}
const productVariantAdd = async (product, id) => {
    try {
        const formData = new FormData();

        // Append fields to the FormData object
        for (const key in product) {
            if (Array.isArray(product[key])) {
                product[key].forEach((item, index) => {
                    if (item instanceof File) {
                        formData.append(`${key}[${index}]`, item); // Handle file uploads
                    } else if (typeof item === 'object' && item !== null) {
                        for (const subKey in item) {
                            formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
                        }
                    } else {
                        formData.append(`${key}[${index}]`, item);
                    }
                });
            } else if (product[key] instanceof File) {
                formData.append(key, product[key]); // Handle single file
            } else if (typeof product[key] === 'object' && product[key] !== null) {
                // Handle nested objects
                for (const subKey in product[key]) {
                    formData.append(`${key}[${subKey}]`, product[key][subKey]);
                }
            } else {
                formData.append(key, product[key]); // Handle primitive values
            }
        }

        // Log the formData content for debugging
        // for (let pair of formData.entries()) {
        //     console.log(pair[0] + '------------------', pair[1]);
        // }

        // Configuration for Axios request
        let config = {
            method: "POST",
            headers: {
                "content-type": "multipart/form-data", // Correct header for FormData
                "authorization": `Bearer ${localStorage.getItem("token")}`
            }
        };

        // Send the FormData to the API
        try {
            let response = await axios.post(`${ADMIN_BASE_URL}/products/variant/${id}`, formData, config);
            // console.log("response : ", response);
            if (response.status === 200) {
                return { status: true, data: response.data.data };
            } else {
                return { status: false, message: "Internal Server Error" };
            }
        } catch (error) {
            // console.log("error!!!!!!!!!!!!!!!!!!!!!!!!!!! : ", error);
            return { status: false, message: error.message };
        }
    } catch (error) {
        // console.error('Error updating product:', error);
        return { result: { status: 500 }, message: 'An error occurred while updating the product' };
    }
}
// Function to update a create new variant combination
// const productVariantCreateNew = async (product, id, selectedAttributes) => {
//     try {
//         const payload = {
//             product,
//             selectedAttributes
//         };
//         // Configuration for Axios request
//         let config = {
//             method: "POST",
//             headers: {
//                 "content-type": "multipart/form-data", // Correct header for FormData
//                 "authorization": `Bearer ${localStorage.getItem("token")}` // Use Bearer token for authorization
//             }
//         };

//         // Send the FormData to the API
//         try {
//             let response = await axios.post(`${ADMIN_BASE_URL}/products/create_variant/${id}`, payload, config);
//             // Handle success response
//             if (response.status === 200) {
//                 return { status: true, data: response.data.data };
//             } else {
//                 return { status: false, message: "Internal Server Error" };
//             }
//         } catch (error) {
//             // Handle any errors that occur during the request
//             return { status: false, message: error.message };
//         }
//     } catch (error) {
//         // Catch any other errors in the process
//         return { result: { status: 500 }, message: 'An error occurred while updating the product' };
//     }
// }
async function productVariantCreateNew(product, id, selectedAttributes, selectedColors, selectedRooms, selectedBanners, selectedPromocode) {
    const payload = {
        product,
        selectedAttributes,
        selectedColors,
        selectedRooms,
        selectedBanners,
        selectedPromocode
    };
    let config3 = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(payload) // Add body here
    };

    console.log(config3);

    try {
        let rawdata = await fetch(`${ADMIN_BASE_URL}/products/create_variant/${id}`, config3); // Only pass config3 here
        if (!rawdata.ok) {
            throw new Error(`Error: ${rawdata.status} - ${rawdata.statusText}`); // Handle errors
        }
        let result = await rawdata.json();
        console.log(result);
        return { status: true, result: "Done", message: "Product Attributes is Created" };
    } catch (error) {
        console.error("Failed to delete attribute:", error);
        return { result: "Error", message: error.message };
    }
}
async function updateProductVariants(payload, id) {

    let config3 = {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify(payload) // Add body here
    };


    try {
        let rawdata = await fetch(`${ADMIN_BASE_URL}/products/manage_variant/${id}`, config3); // Only pass config3 here
        if (!rawdata.ok) {
            throw new Error(`Error: ${rawdata.status} - ${rawdata.statusText}`); // Handle errors
        }
        let result = await rawdata.json();
        console.log(result);
        return { result: "Done", message: "Product Attributes is Updated" };
    } catch (error) {
        console.error("Failed to delete attribute:", error);
        return { result: "Error", message: error.message };
    }
}


// single variant update
const updateProductVariant = async (variantData, id) => {
    // console.log('variantData dttt', variantData)
    try {
        const formData = new FormData();

        // Append fields to the FormData object


        let config = {
            method: "PUT",
            headers: {
                "content-type": "multipart/form-data",
                "authorization": `Bearer ${localStorage.getItem("token")}`
            }
        };

        // // Log the formData content
        // for (let pair of formData.entries()) {
        //     // console.log("pair dttttt",pair[0] + ':', pair[1]);
        // }

        // Make the PUT request
        const response = await axios.put(`${ADMIN_BASE_URL}/products/variant/${id}`, variantData, config);
        // console.log("response : ", response);

        if (response.status === 200) {
            return { status: true, data: response.data.data };
        } else {
            return { status: false, message: "Internal Server Error" };
        }
    } catch (error) {
        // console.error('Error updating product:', error);
        return { status: false, message: error.message };
    }
};

async function ProductStatus(_id, newStatus) {
    let config3 = {
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };
    let obj = {
        is_active: newStatus
    }
    let result = await axios.put(`${ADMIN_BASE_URL}/products/status/${_id}`, obj, config3)
    // let result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Product is Deleted" }
}




async function deleteProduct(_id) {
    // console.log('_id', item)
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };


    let rawdata = await fetch(`${ADMIN_BASE_URL}/products/${_id}`, config3)
    let result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Product is Deleted" }
}


async function deleteVariant(_id) {
    console.log('_id', _id)
    let config3 = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    };


    let rawdata = await fetch(`${ADMIN_BASE_URL}/products/variant/${_id}`, config3)
    let result = await rawdata.json()
    console.log(result);
    return { result: "Done", message: "Variant is Deleted" }
}




async function get_shop_color_room() {
    let config = {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        },

    }
    let url = `${ADMIN_BASE_URL}/products/variant_shop/get_shop_color_room`;
    let response = await axios.get(url, config)
    // console.log("response : ", response)
    if (response.status == 200) {
        return { status: true, data: response.data.data };
    } else {
        return { status: false, message: "Internal Server Error" };
    }
}


export default function ProductContextProvider(props) {
    return <ProductContext.Provider value={
        {
            getProducts: getProducts,
            getSkuExists: getSkuExists,
            getURLExists: getURLExists,
            addProduct: addProduct,
            getProductById: getProductById,
            productVariantAdd: productVariantAdd,
            productVariantCreateNew: productVariantCreateNew,
            getProductVariants: getProductVariants,
            getVariantById: getVariantById,
            updateProductVariant: updateProductVariant,
            updateProduct: updateProduct,
            updateProductOnPopup: updateProductOnPopup,
            updateProductMetaDetails: updateProductMetaDetails,
            ProductStatus: ProductStatus,
            deleteProduct: deleteProduct,
            getAttributesList: getAttributesList,
            updateProductVariants: updateProductVariants,
            get_shop_color_room: get_shop_color_room,
            deleteVariant: deleteVariant,
        }
    }>
        {props.children}
    </ProductContext.Provider>
}
