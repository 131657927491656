import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { SubadminContext } from '../../store/SubadminContextProvider';
import { ToastContainer, toast } from 'react-toastify';

export default function SubAdminAdd() {
    const { addSubadmin } = useContext(SubadminContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        country_code: '',
        phone: '',
        password: '',
        user_role: 'subadmin',
        user_image: ''
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validate = () => {
        let tempErrors = {};
        tempErrors.name = formData.name ? "" : "Full Name is required.";
        tempErrors.email = formData.email ? "" : "Email is required.";
        tempErrors.email = /\S+@\S+\.\S+/.test(formData.email) ? "" : "Email is not valid.";
        tempErrors.country_code = formData.country_code ? "" : "Country Code is required.";
        tempErrors.phone = formData.phone ? "" : "Phone Number is required.";
        tempErrors.phone = /^[0-9]+$/.test(formData.phone) ? "" : "Phone Number is not valid.";
        tempErrors.password = formData.password ? "" : "Password is required.";

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    // const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value
    //     });
    // };



    function handleChange(e) {
        var name = e.target.name;
        var value = e.target.value;
        setFormData((oldData) => {
            return {
                ...oldData,
                [name]: value
            }
        });
    }

    const handleSubmit = async (e) => {



        e.preventDefault();
        if (validate()) {
            setIsLoading(true); // Show loader
            setIsButtonDisabled(true); // Disable button
            try {

                var item = {
                    name: formData.name,
                    email: formData.email,
                    country_code: formData.country_code,
                    phone: formData.phone,
                    password: formData.password,
                    user_role: 'subadmin',
                    user_image: formData.user_image
                }



                const response = await addSubadmin(item);
                // console.log('Sub Admin Added',response.result.data.data._id)
                const _id = response.result.data.data._id


                if (response.result.status == 200) {
                    toast.success('Sub Admin added successfully!');
                    navigate(`/permissions/${_id}`);
                } else {
                    toast.error('Failed to add Sub Admin.');
                    setIsLoading(false); // Hide loader
                    setIsButtonDisabled(false); // Enable button
                }
            } catch (error) {
                toast.error('An error occurred while adding the Sub Admin.');
                setIsLoading(false); // Hide loader
                setIsButtonDisabled(false); // Enable button
            }
        } else {
            toast.error('Please fix the errors in the form.');
            setIsLoading(false); // Hide loader
            setIsButtonDisabled(false); // Enable button
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Add New Sub Admin</h3>
                                </div>
                            </div>
                            <div className="ibox-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Full Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.name && <span className="text-danger">{errors.name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Country Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="country_code"
                                                    value={formData.country_code}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.country_code && <span className="text-danger">{errors.country_code}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Mobile Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    name="password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {errors.password && <span className="text-danger">{errors.password}</span>}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Upload Image</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="user_image"
                                                    value={formData.user_image}
                                                    onChange={handleChange}
                                                    placeholder="Enter image URL or path"
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={isButtonDisabled}>
                                            {isLoading ? 'Sub Admin Adding...' : 'Add Sub Admin'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
