import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ListOrder from './ListOrder';
import ManageOrder from './ManageOrder';
import ViewInvoice from './ViewInvoice';

export default function OrderRoutes() {
    return (
        <Routes>
            <Route path='/orders' element={<ListOrder />} />
            <Route path='/manage-order/:id' element={<ManageOrder />} />
            <Route path='/view-invoice/:id' element={<ViewInvoice />} />
        </Routes>
    )
}
