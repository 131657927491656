import React, { useContext, useEffect, useState } from 'react';
import { Card, Button, Row, Col, Badge, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { ProductContext } from '../../store/ProductContextProvider';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ProductVariant() {
    const { getProductVariants, deleteVariant } = useContext(ProductContext);
    let { id } = useParams();

    const [productVariants, setProductVariants] = useState([]);
    const [productName, setProductName] = useState('');
    const [productSKU, setProductSKU] = useState('');
    const [mainImage, setMainImage] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getAPIData();
    }, []);

    async function getAPIData() {
        if (!id) {
            console.error('ID is undefined or null');
            return;
        }

        try {
            const response = await getProductVariants(id);
            // console.log('Final getProductVariants response', response);

            if (response && response.data) {
                const product = response.data;
                // console.log("product.variants>>>>>>>>>>>>>", product.variants);

                setProductVariants(product.variants || []);
                setProductName(product.product_name || '');
                setProductSKU(product.sku || '');
                setMainImage(product.image || '');
                setCategory(product.category?.name || '');
                setSubCategory(product.sub_category?.name || '');
            }
        } catch (error) {
            console.error('Error fetching product variants:', error);
        }
    }
    const deleteVariantById = async (variantId) => {
        if (window.confirm('Are you sure you want to delete this variant?')) {
            try {
                await deleteVariant(variantId);
                toast.success('Varaint deleted successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                window.scrollTo({ top: 0, behavior: 'smooth' });
                // Remove deleted variant from state
                setProductVariants((prevVariants) =>
                    prevVariants.filter(variant => variant._id !== variantId)
                );
            } catch (error) {
                console.error('Error deleting variant:', error);
                alert('Failed to delete variant');
            }
        }
    };

    return (
        <div className="page-wrapper">
            <Header />
            <ToastContainer />
            <Sidebar />

            <div className="content-wrapper">
                <div className="page-content fade-in-up">
                    <Container fluid>
                        <div className="ibox">
                            <div className="ibox-head d-flex justify-content-between align-items-center" style={{ minHeight: '122px' }}>
                                <div>
                                    <h3>{productName}</h3>
                                    <Badge pill bg="info" className="ml-2">SKU: {productSKU}</Badge>&nbsp;&nbsp;
                                    {category && <Badge pill bg="warning" className="ml-2">Category: {category}</Badge>}&nbsp;&nbsp;
                                    {subCategory && <Badge pill bg="primary" className="ml-2">Sub Category: {subCategory}</Badge>}
                                </div>
                                <Link to="/product-list" className="btn btn-secondary">
                                    Back to Products
                                </Link>
                            </div>
                        </div>
                        {/* Variants Section */}
                        <Row>
                            {productVariants.length > 0 ? (
                                productVariants.map((variant, index) => (
                                    <Col key={index} md={12} className="mb-5">
                                        <Card className="shadow-lg border-0 rounded-lg p-3" style={{ backgroundColor: '#f9f9f9' }}>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div>
                                                        <Card.Title className="mb-1">Variant {index + 1}</Card.Title>
                                                        <Badge pill bg="primary" className="font-weight-light">{variant.color}</Badge>
                                                    </div>
                                                    <div>
                                                        <Badge pill bg="primary" className="mr-2">Price: ₹{variant.price.toLocaleString('en-IN')}</Badge>&nbsp;&nbsp;
                                                        <Badge pill bg="success" className="mr-2">Discount: {variant.discount}%</Badge>&nbsp;&nbsp;
                                                        <Badge pill bg="danger">SKU: {variant.sku}</Badge>
                                                    </div>
                                                </div>

                                                <Card.Text className="mb-3">
                                                    <strong> Product Type: </strong>
                                                    <span className="text"> {variant.product_type} </span><br />
                                                    <strong> Stock Available: </strong>
                                                    <span className="text"> {variant.stock} in stock </span><br />

                                                    <strong>Attributes:</strong>
                                                    {variant.attributes && Object.keys(variant.attributes).length > 0 ? (
                                                        Object.entries(variant.attributes).map(([key, attr], i) => (
                                                            <Badge key={i} pill bg="light" text="dark" className="ml-2">
                                                                {key}: {attr.value}
                                                            </Badge>
                                                        ))
                                                    ) : (
                                                        <span className="text-muted">No Attributes available</span>
                                                    )}
                                                </Card.Text>



                                                <Card.Text className="mb-4">

                                                    <strong>Description:</strong> <span className="text-muted">{variant.short_description || 'No description provided'}</span>
                                                </Card.Text>

                                                {/* Static Image Gallery */}
                                                <Card.Text className="mb-3">
                                                    <strong>Images:</strong><br />
                                                    <div className="d-flex align-items-center flex-wrap">
                                                        {variant.image && (
                                                            <img
                                                                src={variant.image}
                                                                alt={`Main Image of ${variant.color}`}
                                                                style={{ width: '150px', height: '150px', objectFit: 'cover', marginRight: '15px' }}
                                                                className="rounded border"
                                                            />
                                                        )}
                                                        {variant.secondary_images.length > 0 ? (
                                                            variant.secondary_images.map((image, i) => (
                                                                <img
                                                                    key={i}
                                                                    src={image}
                                                                    alt={`Secondary Image ${i + 1}`}
                                                                    style={{ width: '70px', height: '70px', objectFit: 'cover', marginRight: '10px', marginBottom: '10px' }}
                                                                    className="rounded border"
                                                                />
                                                            ))
                                                        ) : (
                                                            <p className="text-muted">No secondary images provided</p>
                                                        )}
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>

                                            {/* Actions Section */}
                                            <Card.Footer className="d-flex justify-content-between bg-light">
                                                <OverlayTrigger
                                                    overlay={<Tooltip id={`tooltip-edit-${variant._id}`}>Edit Variant</Tooltip>}
                                                >
                                                    <Link to={`/product-variant-edit/${variant._id}`} className="btn btn-outline-primary btn-sm">
                                                        <FaEdit /> Edit Variant or Manage Inventory
                                                    </Link>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    overlay={<Tooltip id={`tooltip-delete-${variant._id}`}>Delete Variant</Tooltip>}
                                                >
                                                    <Button variant="outline-danger" size="sm" onClick={() => deleteVariantById(variant._id)}>
                                                        <FaTrash /> Delete Variant
                                                    </Button>
                                                </OverlayTrigger>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <Col>
                                    <p>No product variants found.</p>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

