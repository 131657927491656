import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react"; // Use QRCodeCanvas or QRCodeSVG
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import '../../assets/css/viewInvoice.css';
import { OrderContext } from "../../store/OrderContextProvider";

export default function ViewInvoice() {
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getOrderById } = useContext(OrderContext);

    useEffect(() => {
        fetchOrderDetails();
    }, [id, getOrderById]);

    async function fetchOrderDetails() {
        setLoading(true);
        try {
            const response = await getOrderById(id);
            if (response?.status === 200 && response?.data) {
                setOrderDetails(response.data);
            }
        } catch (error) {
            console.error("Error fetching order details", error);
        } finally {
            setLoading(false);
        }
    }

    const downloadPDF = async () => {
        const invoice = document.getElementById("invoice");
        const canvas = await html2canvas(invoice);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
        pdf.save(`Invoice-${orderDetails.invoice_number}.pdf`);
    };

    if (loading || !orderDetails) {
        return <p>Loading...</p>;
    }

    const {
        order_number,
        invoice_number,
        customer,
        vendor,
        shipping_address,
        billing_address,
        order_details,
        amount_info,
        payment_info
    } = orderDetails;

    return (
        <div className="invoice-container" id="invoice">
            <div className="header">
                <img src="https://www.loopandcut.in/img/logo%20header.png" alt="Company Logo" />

            </div>
            <h1 className="text-center">Invoice</h1><br />
            <div className="invoice-details">
                <div>
                    <p><b>Invoice #: </b>{invoice_number}</p>
                    <p><b>Date: </b>  {orderDetails.createdAt
                        ? new Date(orderDetails.createdAt).toLocaleString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                        })
                        : 'N/A'}</p>
                </div>
                <div>
                    <p><b>Order Number: </b>{order_number}</p>
                    <p><b>Payment Method: </b>{payment_info?.payment_method}</p>
                </div>
            </div>

            <div className="customer-details">
                <div>
                    <p><b>Billed To:</b></p>
                    <p>{customer.firstname} {customer.lastname}</p>
                    <p>{shipping_address.street},{shipping_address.appartment},{shipping_address.landmark}</p>
                    <p>{billing_address.city}, {billing_address.state}, {billing_address.pincode}</p>
                    <p>Email: {customer.email}</p>
                </div>
                <div>
                    <p><b>Shipped To:</b></p>
                    <p>{shipping_address.full_name}</p>
                    <p>{shipping_address.street},{shipping_address.appartment},{shipping_address.landmark}</p>
                    <p>{shipping_address.city}, {shipping_address.state}, {shipping_address.pincode}</p>
                </div>
            </div>

            <div className="sold-by">
                <div>
                    <p><b>Sold By:</b></p>
                    <p>{vendor.business_details.business_name}</p>
                    <p>{vendor.address.street}, {vendor.address.city}, {vendor.address.state} - {vendor.address.postal_code}</p>
                    <p>PAN: {vendor.business_details.pan_number}</p>
                    <p>GST: {vendor.business_details.gst_number}</p>
                </div>
                <div className="qr-code">
                    <QRCodeCanvas value={`Invoice: ${invoice_number}, Order: ${order_number}`} size={150} />
                </div>
            </div>

            <div className="invoice-table">
                <table>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>SKU</th>
                            <th>Gross Amount</th>
                            <th>Quantity</th>
                            <th>Taxable Value</th>
                            <th>CGST (9%)</th>
                            <th>SGST (9%)</th>
                            <th>IGST (18%)</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order_details.map((item) => {
                            const totalAmount = item.selling_price * item.quantity;
                            const taxableValue = (totalAmount * 100) / 118; // Excluding GST
                            const cgst = taxableValue * 0.09; // 9% CGST
                            const sgst = taxableValue * 0.09; // 9% SGST
                            const igst = taxableValue * 0.18; // 18% IGST
                            const isIntrastate = shipping_address.state.toLowerCase() === vendor.address.state.toLowerCase(); // Determine intrastate or interstate

                            return (
                                <tr key={item._id}>
                                    <td>{item.product_name}</td>
                                    <td>{item.sku}</td>
                                    <td>₹{item.selling_price.toLocaleString('en-IN')}</td>
                                    <td>{item.quantity}</td>
                                    <td>₹{Number(taxableValue.toFixed(2)).toLocaleString('en-IN')}</td>
                                    {isIntrastate ? (
                                        <>
                                            <td>₹{Number(cgst.toFixed(2)).toLocaleString('en-IN')}</td>
                                            <td>₹{Number(sgst.toFixed(2)).toLocaleString('en-IN')}</td>
                                            <td>₹0.00</td> {/* Hide IGST */}
                                        </>
                                    ) : (
                                        <>
                                            <td>₹0.00</td> {/* Hide CGST */}
                                            <td>₹0.00</td> {/* Hide SGST */}
                                            <td>₹{Number(igst.toFixed(2)).toLocaleString('en-IN')}</td>
                                        </>
                                    )}
                                    <td>₹{totalAmount.toLocaleString('en-IN')}</td>
                                </tr>

                            );
                        })}
                    </tbody>
                </table>


            </div>

            <div className="total">

                <p><b>Sub Total: </b>₹{amount_info.sub_total.toLocaleString('en-IN')}</p>
                <p><b>Discount: </b>₹{amount_info.discounted_amount.toLocaleString('en-IN')}</p>
                <p><b>Grand Total: </b>₹{amount_info.total_amount_paid_by_customer.toLocaleString('en-IN')}</p>
            </div>

            <div className="footer">
                <p>Thank you for your purchase! If you have any questions, please contact us at <a href="mailto:contact@loopandcut.in.">contact@loopandcut.in.</a></p>
            </div>

            <div className="actions">
                {/* <button onClick={downloadPDF}>Download PDF</button> */}
                <button onClick={() => window.print()}>Print</button>
            </div>
        </div>
    );
}
