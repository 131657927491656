import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { CategoryContext } from '../../store/CategoryContextProvider';
import { ProductContext } from '../../store/ProductContextProvider';

export default function ProductEdit() {
    const navigate = useNavigate();
    const { getCategory } = useContext(CategoryContext);
    const { getProductById, updateProductOnPopup, get_shop_color_room } = useContext(ProductContext);

    const [formData, setFormData] = useState({
        _id: '',
        product_name: '',
        product_url: '',
        category_id: '',
        shopbycolor: [],
        shopbyroom: [],
        shopbybanner: [],
        shopbypromocode: [],
        custom_attributes: {}
    });

    const [categories, setCategories] = useState([]);
    const [shop_color, setShop_color] = useState([]);
    const [shop_room, setShop_room] = useState([]);
    const [banner, setBanner] = useState([]);
    const [promocode, setPromocode] = useState([]);
    const [attributes, setAttributes] = useState([]);

    const { _id } = useParams();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchCategories();
        fetchProductDetails();
        fetchColorRoomData();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await getCategory(1, 50, '');
            if (response.result === 'Done') {
                const allCategories = response.data.docs;
                setCategories(allCategories.filter(cat => cat.parent_id === '66ad2ac0a4c6b61fd84863b8'));
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchProductDetails = async () => {
        if (!_id) return;

        try {
            const response = await getProductById(_id);
            if (response && response.data) {
                // Ensure shopbybanner and shopbypromocode have arrays (even if empty)
                setFormData({
                    ...response.data,
                    shopbybanner: response.data.shopbybanner || [],
                    shopbypromocode: response.data.shopbypromocode || []
                });
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };


    const fetchColorRoomData = async () => {
        try {
            const response = await get_shop_color_room(_id);
            if (response && response.data) {
                setShop_color(response.data.ShopbyColorData || []);
                setShop_room(response.data.ShopbyRoomData || []);
                setBanner(response.data.BannerData || []);
                setPromocode(response.data.PromocodeData || []);
                setAttributes(response.data.AttributesData || []);
            }
        } catch (error) {
            console.error('Error fetching color/room data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (key, id) => {
        setFormData((prevData) => {
            const updatedArray = prevData[key].includes(id)
                ? prevData[key].filter(item => item !== id)
                : [...prevData[key], id];
            return { ...prevData, [key]: updatedArray };
        });
    };

    const updateCustomAttributes = (attributeId, attributeName, valueId, valueName, valueImage, type) => {
        setFormData((prevData) => {
            const updatedAttributes = { ...prevData.custom_attributes };

            // Check if the attribute already exists
            if (updatedAttributes[attributeName]) {
                const existingValues = updatedAttributes[attributeName].values || [];

                // Check if the value is already selected
                const valueIndex = existingValues.findIndex((value) => value.id === valueId);

                if (valueIndex !== -1) {
                    // If value exists, remove it (uncheck logic)
                    existingValues.splice(valueIndex, 1);
                } else {
                    // If value does not exist, add it (check logic)
                    existingValues.push({ id: valueId, value: valueName, image: valueImage });
                }

                updatedAttributes[attributeName].values = existingValues;
            } else {
                // If attribute does not exist, create it with the new value
                updatedAttributes[attributeName] = {
                    type: type,
                    values: [{ id: valueId, value: valueName, image: valueImage }],
                };
            }

            return { ...prevData, custom_attributes: updatedAttributes };
        });
    };


    const handleSubmit = async () => {
        try {
            // Create a filtered object containing only form-related keys
            const filteredFormData = {};
            const staticKeys = ['_id', 'product_name', 'product_url', 'category_id', 'shopbycolor', 'shopbyroom', 'shopbybanner', 'shopbypromocode'];
            staticKeys.forEach(key => {
                if (formData[key] !== undefined) {
                    filteredFormData[key] = formData[key];
                }
            });

            const customAttributes = {};
            attributes.forEach(attribute => {
                if (formData.custom_attributes?.[attribute.attribute_name]) {
                    customAttributes[attribute.attribute_name] = formData.custom_attributes[attribute.attribute_name];
                }
            });
            filteredFormData.custom_attributes = customAttributes;

            await updateProductOnPopup(filteredFormData);
            localStorage.setItem('toast', 'Product Updated Successfully..');
            navigate('/product-list');
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    return (
        <div className="page-wrapper">
            <Header />
            <Sidebar />

            <div className="content-wrapper">
                <div className="page-content fade-in-up">
                    <div className="ibox">
                        <div className="ibox-head">
                            <div className="ibox-title">
                                <h3>Edit Product</h3>
                            </div>
                            <Link to="/product-list" className="btn btn-secondary pull-right">
                                Back To Product
                            </Link>
                        </div>
                        <div className="ibox-body">
                            <Form>
                                <Row className="mb-4">
                                    <Col md={6}>
                                        <Form.Group controlId="formProductName">
                                            <Form.Label className="font-weight-bold">Product Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="product_name"
                                                value={formData.product_name}
                                                onChange={handleInputChange}
                                                placeholder="Enter product name"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="formCategory">
                                            <Form.Label className="font-weight-bold">Category</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="category_id"
                                                value={formData.category_id}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select a Category</option>
                                                {categories.map(cat => (
                                                    <option key={cat._id} value={cat._id}>{cat.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Shop By Color */}
                                <div className="mb-4 p-3" style={{ border: '1px solid #ddd', borderRadius: '5px' }}>
                                    <h5 className="font-weight-bold mb-3">Shop By Color</h5>
                                    <div className="d-flex flex-wrap">
                                        {shop_color.map(color => (
                                            <Form.Check
                                                key={color._id}
                                                type="checkbox"
                                                label={color.name}
                                                checked={formData.shopbycolor.includes(color._id)}
                                                onChange={() => handleCheckboxChange('shopbycolor', color._id)}
                                                className="me-3 mb-2"
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Shop By Room */}
                                <div className="mb-4 p-3" style={{ border: '1px solid #ddd', borderRadius: '5px' }}>
                                    <h5 className="font-weight-bold mb-3">Shop By Room</h5>
                                    <div className="d-flex flex-wrap">
                                        {shop_room.map(room => (
                                            <Form.Check
                                                key={room._id}
                                                type="checkbox"
                                                label={room.name}
                                                checked={formData.shopbyroom.includes(room._id)}
                                                onChange={() => handleCheckboxChange('shopbyroom', room._id)}
                                                className="me-3 mb-2"
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Shop By Banner */}
                                <div className="mb-4 p-3" style={{ border: '1px solid #ddd', borderRadius: '5px' }}>
                                    <h5 className="font-weight-bold mb-3">Shop By Banner</h5>
                                    <div className="d-flex flex-wrap">
                                        {banner.map(ban => (
                                            <Form.Check
                                                key={ban._id}
                                                type="checkbox"
                                                label={ban.banner_title}
                                                checked={formData.shopbybanner.includes(ban._id)}
                                                onChange={() => handleCheckboxChange('shopbybanner', ban._id)}
                                                className="me-3 mb-2"
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Shop By Promocode */}
                                <div className="mb-4 p-3" style={{ border: '1px solid #ddd', borderRadius: '5px' }}>
                                    <h5 className="font-weight-bold mb-3">Shop By Promocode</h5>
                                    <div className="d-flex flex-wrap">
                                        {promocode.map(promo => (
                                            <Form.Check
                                                key={promo._id}
                                                type="checkbox"
                                                label={promo.title}
                                                checked={formData.shopbypromocode.includes(promo._id)}
                                                onChange={() => handleCheckboxChange('shopbypromocode', promo._id)}
                                                className="me-3 mb-2"
                                            />
                                        ))}
                                    </div>
                                </div>

                                {/* Custom Attributes */}
                                <div className="mb-4">
                                    <h4 className="text-center mb-4 font-weight-bold">Custom Attributes</h4>
                                    {attributes && attributes.length > 0 ? (
                                        attributes.map(attribute => (
                                            <div key={attribute._id} className="mb-4 p-3" style={{ border: '1px solid #ddd', borderRadius: '5px' }}>
                                                <Form.Group>
                                                    <Form.Label className="font-weight-bold" style={{ fontSize: '1.2rem' }}>{attribute.attribute_name}</Form.Label>
                                                    {attribute.attribute_type === 'CheckBox' && (
                                                        <div className="d-flex flex-wrap">
                                                            {attribute.attributes_values.map(value => (
                                                                <Form.Check
                                                                    key={value._id}
                                                                    type="checkbox"
                                                                    label={value.name}
                                                                    checked={
                                                                        formData.custom_attributes?.[attribute.attribute_name]?.values?.some(
                                                                            selectedValue => selectedValue.id === value._id
                                                                        ) || false
                                                                    }
                                                                    onChange={() =>
                                                                        updateCustomAttributes(
                                                                            attribute._id,
                                                                            attribute.attribute_name,
                                                                            value._id,
                                                                            value.name,
                                                                            null,
                                                                            'custom_attribute'
                                                                        )
                                                                    }
                                                                    disabled={!value.isActive}
                                                                    className="me-3 mb-2"
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="text-center">No custom attributes available.</div>
                                    )}
                                </div>

                                <div className="text-center">
                                    <Button variant="primary" size="lg" onClick={handleSubmit}>Save Changes</Button>
                                </div>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
