import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function EditProductModal({ show, handleClose, product, handleUpdate }) {
    const [productName, setProductName] = useState(product.product_name || '');
    const [product_url, setProductURL] = useState(product.product_url || '');
    const [meta_title, setMetaTitle] = useState(product.meta_title || '');
    const [meta_description, setMetaDescription] = useState(product.meta_description || '');
    const [meta_keywords, setMetaKeywords] = useState(product.meta_keywords || '');
    const [focus_keyword, setFocusKeyword] = useState(product.focus_keyword || '');
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleSubmit = () => {
        setSaving(true);
        const updatedProduct = {
            _id: product._id,
            meta_title,
            meta_description,
            meta_keywords,
            focus_keyword,
        };
        handleUpdate(updatedProduct);
        setTimeout(() => {
            setSaving(false);
            handleClose();
        }, 1000);
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <div className="modal-card">
                <Modal.Header closeButton className="modal-header-custom">
                    <Modal.Title className="text-center">Edit Product Meta Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formProductName" className="mb-3">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                disabled
                                className="input-field"
                            />
                        </Form.Group>
                        <Form.Group controlId="formProductURL" className="mb-3">
                            <Form.Label>Product URL</Form.Label>
                            <Form.Control
                                type="text"
                                value={product_url}
                                onChange={(e) => setProductURL(e.target.value)}
                                disabled
                                className="input-field"
                            />
                        </Form.Group>
                        <Form.Group controlId="formMetaTitle" className="mb-3">
                            <Form.Label>
                                Meta Title <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={meta_title}
                                onChange={(e) => setMetaTitle(e.target.value)}
                                placeholder="Enter meta title"
                                className="input-field"
                            />
                        </Form.Group>
                        <Form.Group controlId="formMetaKeywords" className="mb-3">
                            <Form.Label>
                                Meta Keywords <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={meta_keywords}
                                onChange={(e) => setMetaKeywords(e.target.value)}
                                placeholder="Enter meta keywords, separated by commas"
                                className="input-field"
                            />
                        </Form.Group>
                        <Form.Group controlId="formMetaDescription" className="mb-3">
                            <Form.Label>
                                Meta Description <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={meta_description}
                                onChange={(e) => setMetaDescription(e.target.value)}
                                placeholder="Enter a detailed meta description"
                                className="textarea-field"
                            />
                        </Form.Group>
                        <Form.Group controlId="formFocusKeyword" className="mb-3">
                            <Form.Label>
                                Focus Keyword <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={focus_keyword}
                                onChange={(e) => setFocusKeyword(e.target.value)}
                                placeholder="Enter the primary focus keyword"
                                className="input-field"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-custom">
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        className="rounded-button"
                        disabled={saving}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        className="rounded-button"
                        disabled={saving}
                    >
                        {saving ? 'Saving...' : 'Save Changes'}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
}

export default EditProductModal;
