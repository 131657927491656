import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { OrderContext } from '../../store/OrderContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/css/banner.css';


export default function ListOrder() {
    const [order, setOrder] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCounts, setTotalCounts] = useState(1);
    const [itemsPerPage] = useState(20); // Change this value as needed
    const [searchQuery, setSearchQuery] = useState('');
    const { getOrder, updateOrderStatus } = useContext(OrderContext);

    // Modal state management
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [modalAction, setModalAction] = useState('');
    const [confirmItem, setConfirmItem] = useState(null);

    // Get all data
    async function getAPIData(page) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const response = await getOrder(page, itemsPerPage, searchQuery);
        if (response.result === "Done") {
            setOrder(response.data.docs);
            setTotalPages(response.data.totalPages);
            setTotalCounts(response.data.totalDocs);
        } else {
            alert(response.message);
        }
    }



    // Pagination
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    // Search
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        getAPIData(currentPage);
    };
    // Handle Drag and Drop

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case "pending":
                return "badge-warning"; // Yellow
            case "confirmed":
                return "badge-info"; // Light blue
            case "processing":
                return "badge-secondary"; // Grey
            case "cancelled":
                return "badge-danger"; // Red
            case "shipped":
                return "badge-primary"; // Blue
            case "out_for_delivery":
                return "badge-light"; // Light grey
            case "delivered":
                return "badge-success"; // Green
            case "returned":
                return "badge-warning"; // Dark grey
            case "payment_not_completed":
                return "badge-warning"; // Yellow
            case "refund_initiated":
                return "badge-primary"; // Blue
            case "refund_completed":
                return "badge-success"; // Green
            case "failed":
                return "badge-danger"; // Red
            default:
                return "badge-secondary"; // Default color (grey)
        }
    };

    const getPaymentStatusBadgeClass = (status) => {
        switch (status) {
            case "paid":
                return "badge-success"; // Green for successful payment
            case "unpaid":
                return "badge-warning"; // Yellow for pending payment
            case "failed":
                return "badge-danger"; // Red for failed payment
            case "processing":
                return "badge-primary"; // Blue for payment processing
            case "refunded":
                return "badge-info"; // Light blue for refunded payment
            case "partially_paid":
                return "badge-secondary"; // Grey for partial payment
            default:
                return "badge-secondary"; // Default grey for unknown statuses
        }
    };



    useEffect(() => {
        getAPIData(currentPage);
        const toastr = localStorage.getItem('toast');
        if (toastr) {
            toast.success(toastr, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('toast');
        }
    }, [currentPage]);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Order - List ({totalCounts})</h3>
                                </div>
                                <div className="flexbox mb-4">
                                    <div className="input-group-icon input-group-icon-left mr-3">
                                        <br />
                                        <form onSubmit={handleSearchSubmit} className="search-container">
                                            <input className="form-control form-control-rounded form-control-solid" id="key-search"
                                                type="text" placeholder="Search Order..." value={searchQuery}
                                                onChange={handleSearchChange} />
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="ibox-body">

                                <div className="table-responsive row">
                                    <table className="table table-bordered table-hover text-center" id="datatable">
                                        <thead className="thead-default thead-lg">
                                            <tr>
                                                <th>Sr No</th>
                                                {/* <th>Image</th> */}
                                                <th>Order Number</th>
                                                <th>Customer Details</th>
                                                <th>Amount Info</th>
                                                <th>Payment Details</th>
                                                <th>Status</th>
                                                <th className="no-sort">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order.length > 0 ? order.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                    {/* <td><img src={item.profile_image} width="90px" height="70px" alt="Customer" /></td> */}

                                                    <td> <Link
                                                        to={`/manage-order/${item._id}`}
                                                        className="text-primary"
                                                        style={{
                                                            display: 'block',
                                                            marginBottom: '5px',
                                                            cursor: 'pointer',
                                                            textDecoration: 'none' // Added style for removing underline
                                                        }}>
                                                        #{item.order_number}<br />

                                                    </Link>  </td>

                                                    <td>
                                                        <b>Name</b>: {item.customer ? item.customer.firstname : ''} {item.customer ? item.customer.lastname : ''}
                                                        <br /> <b>Email </b>: {item.customer ? item.customer.email : ''}    <br /> <b>Mobile </b>: {item.customer ? item.customer.mobile_no : ''}
                                                    </td>
                                                    <td><b>Total: </b>₹{(item.amount_info.total_amount_paid_by_customer).toLocaleString('en-IN')}
                                                        <br /><b>Discounted: </b>₹{Number(item.amount_info.discounted_amount.toFixed(2)).toLocaleString('en-IN')}
                                                        <br /><b>Total Payble Amount: </b>₹{item.amount_info.total_amount_paid_by_customer.toLocaleString('en-IN')}
                                                    </td>
                                                    <td>
                                                        <b>Mode: </b>{(item.payment_info.payment_method)}<br />
                                                        {item.createdAt
                                                            ? new Date(item.createdAt).toLocaleString('en-GB', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                second: '2-digit',
                                                            })
                                                            : 'N/A'}
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        <div className="mb-2">
                                                            <span
                                                                className={`badge ${getStatusBadgeClass(item.order_status)} px-3 py-1 rounded-pill shadow-sm`}
                                                            >
                                                                {item.order_status === 'delivered' && <i className="bi bi-check-circle-fill me-1"></i>}
                                                                {item.order_status
                                                                    .replace(/_/g, " ")
                                                                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span
                                                                className={`badge ${getPaymentStatusBadgeClass(item.payment_status)} px-3 py-1 rounded-pill shadow-sm`}
                                                            >
                                                                {item.payment_status === 'paid' && <i className="bi bi-credit-card-fill me-1"></i>}
                                                                {item.payment_status.replace(/_/g, " ").toUpperCase()}
                                                            </span>
                                                        </div>
                                                    </td>


                                                    <td className="text-center">
                                                        {/* Manage Order Link */}
                                                        <Link
                                                            to={`/manage-order/${item._id}`}
                                                            className="badge badge-primary badge-pill"
                                                            style={{
                                                                display: "block",
                                                                marginBottom: "5px",
                                                                cursor: "pointer",
                                                                textDecoration: "none", // Remove underline
                                                            }}
                                                        >
                                                            Manage Order
                                                        </Link>


                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td colSpan={9}>Orders Loading..</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                                    <span className="pagination-info">{`Page ${currentPage} of ${totalPages}`}</span>
                                    <button className="btn btn-primary" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
