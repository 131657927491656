import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/sidebar.css'; // Import your specific CSS file for styling
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  var navigate = useNavigate();

  async function logout() {
    let obj = { login_user_id: localStorage.getItem("userid") };
    var response = await fetch("/logout", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(obj),
    });

    localStorage.clear();
    navigate("/");
  }

  return (
    <div className="sidebar">
      <ul className="sidebar-menu">
        <li className="sidebar-item">
          <Link to="/dashboard" className="sidebar-link">Home</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/banner" className="sidebar-link">Banner</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/attributes" className="sidebar-link">Attributes</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/category" className="sidebar-link">Categories</Link>
          <hr className="sidebar-divider" />
        </li>
        {/* <li className="sidebar-item">
          <Link to="/subadmin-list" className="sidebar-link">Sub Admin</Link>
          <hr className="sidebar-divider" />
        </li> */}

        <li className="sidebar-item">
          <Link to="/shopbyroom" className="sidebar-link">ShopbyRoom</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/shopbycolor" className="sidebar-link">ShopbyColor</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/product-list" className="sidebar-link">Products</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/orders" className="sidebar-link">Orders</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/Promocode" className="sidebar-link">Promocode</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/customer" className="sidebar-link">Customer</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/blogs" className="sidebar-link">Blogs</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/contactus" className="sidebar-link">Contactus</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/newsletter" className="sidebar-link">Newsletter</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/searchKeyword" className="sidebar-link">SearchKeyword</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/testimonial" className="sidebar-link">Testimonial</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/faqs" className="sidebar-link">Faqs</Link>
          <hr className="sidebar-divider" />
        </li>
        <li className="sidebar-item">
          <Link to="/returntypes" className="sidebar-link">ReturnTypes</Link>
          <hr className="sidebar-divider" />
        </li>



        <li className="sidebar-item">
          <button className="dropdown-item" onClick={logout}>Logout</button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
