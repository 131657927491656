import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Col, Row, Badge } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { ProductContext } from '../../store/ProductContextProvider';
import '../../assets/css/ProductAttributesAdd.css'; // Import custom styles

export default function ProductAttributesAdd() {
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [product, setProduct] = useState({});
    const [attributes, setAttributes] = useState([]);
    const [shop_color, setShop_color] = useState([]);
    const [shop_room, setShop_room] = useState([]);
    const [banner, setBanner] = useState([]);
    const [promocode, setPromocode] = useState([]);
    const [sortedAttributes, setSortedAttributes] = useState({ main: [], custom: [] }); // Initialize properly
    const [selectedAttributes, setSelectedAttributes] = useState({});
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedBanners, setSelectedBanners] = useState([]);
    const [selectedPromocode, setSelectedPromocode] = useState([]);

    const { getProductById, productVariantAdd, getAttributesList, productVariantCreateNew, get_shop_color_room } = useContext(ProductContext);
    let navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        getColorRoomData()
        getAPIData();
        getAttributesListData();
    }, []);


    async function getColorRoomData() {


        try {
            const response = await get_shop_color_room(id);
            console.log("get_shop_color_room Dataaaaaaa>>>>>>>>", response)

            if (response && response.data) {
                setShop_color(response.data.ShopbyColorData);
                setShop_room(response.data.ShopbyRoomData);
                setBanner(response.data.BannerData);
                setPromocode(response.data.PromocodeData);



            }
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    }



    async function getAPIData() {
        if (!id) return;

        try {
            const response = await getProductById(id);
            if (response && response.data) {
                setProduct(response.data);
            }
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    }

    async function getAttributesListData() {
        try {
            const response = await getAttributesList();
            if (response && response.data && response.data.docs.length > 0) {
                setAttributes(response.data.docs);
                // Separate main_attributes and custom_attributes
                const mainAttributes = response.data.docs.filter(attr => attr.attribute_source === 'main_attributes');
                const customAttributes = response.data.docs.filter(attr => attr.attribute_source === 'custom_attributes');

                // Store them separately
                setSortedAttributes({ main: mainAttributes, custom: customAttributes });
                console.log('Main Attributes:', mainAttributes);
                console.log('Custom Attributes:', customAttributes);
            }
        } catch (error) {
            console.error('Error fetching attributes:', error);
        }
    }

    async function postData(e) {


        e.preventDefault();
        setIsLoading(true);
        setIsButtonDisabled(true);

        console.log({
            product,
            selectedAttributes,
            selectedColors,
            selectedRooms,
            selectedBanners,
            selectedPromocode


        });


        const response = await productVariantCreateNew(product, id, selectedAttributes, selectedColors, selectedRooms, selectedBanners, selectedPromocode); // Pass selected attributes here
        if (response.status === true) {
            localStorage.setItem('toast', 'Product variant has been updated successfully');
            navigate(`/product-attributes-manage/${id}`);
        } else {
            alert(response.message);
            setIsLoading(false);
            setIsButtonDisabled(false);
        }
    }


    const handleInputColor = (id) => {
        setSelectedColors((prevSelected) => {
            if (prevSelected.includes(id)) {
                // If already selected, remove it
                return prevSelected.filter((colorId) => colorId !== id);
            } else {
                // If not selected, add it
                return [...prevSelected, id];
            }
        });
    };

    const handleInputRoom = (id) => {
        setSelectedRooms((prevSelected) => {
            if (prevSelected.includes(id)) {
                // If already selected, remove it
                return prevSelected.filter((roomId) => roomId !== id);
            } else {
                // If not selected, add it
                return [...prevSelected, id];
            }
        });
    };


    const handleInputBanner = (id) => {
        setSelectedBanners((prevSelected) => {
            if (prevSelected.includes(id)) {
                // If already selected, remove it
                return prevSelected.filter((bannerId) => bannerId !== id);
            } else {
                // If not selected, add it
                return [...prevSelected, id];
            }
        });
    };


    const handleInputPromocode = (id) => {
        setSelectedPromocode((prevSelected) => {
            if (prevSelected.includes(id)) {
                // If already selected, remove it
                return prevSelected.filter((promocodeId) => promocodeId !== id);
            } else {
                // If not selected, add it
                return [...prevSelected, id];
            }
        });
    };

    const handleInputChange = (attributeId, attributeName, valueId, value, image, attributeType) => {
        setSelectedAttributes((prevSelected) => {
            const updated = { ...prevSelected };


            // Check if attributeName (like 'Sizes', 'Colors') already exists
            if (updated[attributeName]) {
                // If it exists, check if it's an array
                if (Array.isArray(updated[attributeName].values)) {
                    // Find the index of the current value based on the valueId
                    const index = updated[attributeName].values.findIndex(attr => attr.id === valueId);
                    if (index > -1) {
                        // If the attribute exists, remove it (for toggle behavior)
                        updated[attributeName].values.splice(index, 1);
                    } else {
                        // Otherwise, add the new valueId and value to the array
                        updated[attributeName].values.push({ id: valueId, value, image });
                    }
                } else {
                    // If the attribute wasn't an array, convert it into an array
                    updated[attributeName].values = [
                        updated[attributeName].values,
                        { id: valueId, value, image }
                    ];
                }
            } else {
                // If attributeName doesn't exist, create a new entry with the name and type
                updated[attributeName] = {
                    type: attributeType, // Add the attribute_type here
                    values: [{ id: valueId, value, image }]
                };
            }

            return updated;
        });
    };


    return (
        <div className="page-wrapper">
            <Header />
            <Sidebar />

            <div className="content-wrapper">
                <div className="page-content fade-in-up">
                    <div className="ibox">
                        <div className="ibox-head">
                            <div className="ibox-title">
                                <h3 className="text-primary">Product Attributes Add</h3>
                            </div>
                            <Link to="/product-list" className="btn btn-secondary pull-right">
                                Back to Products
                            </Link>
                        </div>
                        <div className="ibox-body">
                            <div className="card shadow mb-4">
                                <div className="card-header bg-success text-white">Product Info</div>
                                <div className="card-body">
                                    <Row className="mb-3 align-items-center">
                                        <Col md={8}>
                                            <h4 className="font-weight-bold">{product.product_name}</h4>
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge pill bg="info" className="me-2">SKU: {product.sku}</Badge>
                                                    {product.category && (
                                                        <Badge pill bg="warning" className="me-2">
                                                            Category: {product.category.name}
                                                        </Badge>
                                                    )}
                                                    {product.sub_category && (
                                                        <Badge pill bg="warning" className="me-2">
                                                            Sub Category: {product.sub_category.name}
                                                        </Badge>
                                                    )}
                                                    {product.product_id && (
                                                        <Badge pill bg="primary" className="me-2">
                                                            Product Id: {product.product_id}
                                                        </Badge>
                                                    )}
                                                    {product.tax && (
                                                        <Badge pill bg="info" className="me-2">
                                                            <b>Tax</b>: {product.tax}%
                                                        </Badge>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} className="text-end">
                                            {product.image && (
                                                <div className="mb-2">
                                                    <img
                                                        src={product.image}
                                                        alt="Existing"
                                                        className="img-thumbnail"
                                                    />
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <Form onSubmit={postData} className="mt-4 p-4 rounded shadow">
                                {/* Centered title */}

                                {/* Main Attributes Card */}
                                <div className="mb-4">

                                    <h4 className="mb-4 text-center"><b>Select Main Attributes</b></h4>

                                    {sortedAttributes.main && sortedAttributes.main.length > 0 ? (
                                        sortedAttributes.main.map(attribute => (
                                            <div key={attribute._id} className="mb-4">

                                                <Form.Group className="mb-4">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <Form.Label
                                                            className="font-weight-bold"
                                                            style={{ fontSize: '1.5rem', marginRight: '10px', color: 'black', fontWeight: 'bold' }}
                                                        >
                                                            {attribute.attribute_name}
                                                        </Form.Label>
                                                    </div>

                                                    {/* Render based on attribute type */}
                                                    {attribute.attribute_type === 'CheckBox' && (
                                                        <div className="d-flex flex-wrap">
                                                            {attribute.attributes_values.map(value => (
                                                                <div key={value._id} className="card me-3 mb-3" style={{ width: '16rem' }}>
                                                                    <div className="card-body">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={value._id}
                                                                            label={value.name}
                                                                            onChange={() => handleInputChange(attribute._id, attribute.attribute_name, value._id, value.name, value.image, 'main_attribute')}
                                                                            disabled={!value.isActive}
                                                                            style={{ fontSize: '1.2rem' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {attribute.attribute_type === 'RadioButton' && (
                                                        <div className="d-flex flex-wrap">
                                                            {attribute.attributes_values.map((value, index) => (
                                                                <div key={value._id} className="card me-3 mb-3" style={{ width: '16rem' }}>
                                                                    <div className="card-body">
                                                                        <Form.Check
                                                                            type="radio"
                                                                            id={value._id}
                                                                            name={`radio-${attribute._id}`} // Grouping the radio buttons by attribute ID
                                                                            label={value.name}
                                                                            onChange={() => handleInputChange(attribute._id, attribute.attribute_name, value._id, value.name, value.image, 'main_attribute')}
                                                                            disabled={!value.isActive}
                                                                            style={{ fontSize: '1.2rem' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {attribute.attribute_type === 'Textbox' && (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={`Enter ${attribute.attribute_name}`}
                                                            onChange={(e) => handleInputChange(attribute._id, attribute.attribute_name, '', e.target.value, 'main_attribute')} // Updated to handle Textbox
                                                            className="mb-2"
                                                            style={{ fontSize: '1.2rem' }} // Larger font size
                                                        />
                                                    )}
                                                    {attribute.attribute_type === 'Textarea' && (
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder={`Enter ${attribute.attribute_name}`}
                                                            onChange={(e) => handleInputChange(attribute._id, attribute.attribute_name, '', e.target.value, 'main_attribute')} // Updated to handle Textarea
                                                            className="mb-2"
                                                            style={{ fontSize: '1.2rem' }} // Larger font size
                                                        />
                                                    )}
                                                    {attribute.attribute_type === 'DropdownList' && (
                                                        <Form.Select
                                                            onChange={(e) => {
                                                                const selectedValue = attribute.attributes_values.find(value => value._id === e.target.value);
                                                                handleInputChange(attribute._id, attribute.attribute_name, selectedValue._id, selectedValue.name, selectedValue.image, 'main_attribute');
                                                            }}
                                                            className="mb-2"
                                                            style={{ fontSize: '1.2rem' }} // Larger font size
                                                        >
                                                            <option>Select {attribute.attribute_name}</option>
                                                            {attribute.attributes_values.map(value => (
                                                                <option key={value._id} value={value._id} disabled={!value.isActive}>
                                                                    {value.name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    )}
                                                </Form.Group>
                                            </div>
                                        ))
                                    ) : (
                                        <div>No main attributes available.</div> // Fallback for no attributes
                                    )}
                                </div>

                                {/* Custom Attributes Card */}
                                <div className="mb-4">
                                    <h4 className="mb-4 text-center">Select Custom Attributes</h4>

                                    {sortedAttributes.custom && sortedAttributes.custom.length > 0 ? (
                                        sortedAttributes.custom.map(attribute => (
                                            <div key={attribute._id} className="mb-4">
                                                <hr />
                                                <Form.Group className="mb-4">
                                                    <div className="d-flex align-items-center mb-2">
                                                        <Form.Label
                                                            className="font-weight-bold"
                                                            style={{ fontSize: '1.5rem', marginRight: '10px', color: 'black', fontWeight: 'bold' }}
                                                        >
                                                            {attribute.attribute_name}
                                                        </Form.Label>
                                                    </div>

                                                    {/* Render based on attribute type */}
                                                    {attribute.attribute_type === 'CheckBox' && (
                                                        <div className="d-flex flex-wrap">
                                                            {attribute.attributes_values.map(value => (
                                                                <div key={value._id} className="card me-3 mb-3" style={{ width: '16rem' }}>
                                                                    <div className="card-body">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={value._id}
                                                                            label={value.name}
                                                                            onChange={() => handleInputChange(attribute._id, attribute.attribute_name, value._id, value.name, value.image, 'custom_attribute')}
                                                                            disabled={!value.isActive}
                                                                            style={{ fontSize: '1.2rem' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {attribute.attribute_type === 'RadioButton' && (
                                                        <div className="d-flex flex-wrap">
                                                            {attribute.attributes_values.map((value, index) => (
                                                                <div key={value._id} className="card me-3 mb-3" style={{ width: '16rem' }}>
                                                                    <div className="card-body">
                                                                        <Form.Check
                                                                            type="radio"
                                                                            id={value._id}
                                                                            name={`radio-${attribute._id}`} // Grouping the radio buttons by attribute ID
                                                                            label={value.name}
                                                                            onChange={() => handleInputChange(attribute._id, attribute.attribute_name, value._id, value.name, value.image, 'custom_attribute')}
                                                                            disabled={!value.isActive}
                                                                            style={{ fontSize: '1.2rem' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}

                                                    {attribute.attribute_type === 'Textbox' && (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={`Enter ${attribute.attribute_name}`}
                                                            onChange={(e) => handleInputChange(attribute._id, e.target.value, 'custom_attribute')}
                                                            className="mb-2"
                                                            style={{ fontSize: '1.2rem' }} // Larger font size
                                                        />
                                                    )}
                                                    {attribute.attribute_type === 'Textarea' && (
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder={`Enter ${attribute.attribute_name}`}
                                                            onChange={(e) => handleInputChange(attribute._id, e.target.value, 'custom_attribute')}
                                                            className="mb-2"
                                                            style={{ fontSize: '1.2rem' }} // Larger font size
                                                        />
                                                    )}
                                                    {attribute.attribute_type === 'DropdownList' && (
                                                        <Form.Select
                                                            onChange={(e) => {
                                                                const selectedValue = attribute.attributes_values.find(value => value._id === e.target.value);
                                                                handleInputChange(attribute._id, attribute.attribute_name, selectedValue._id, selectedValue.name, selectedValue.image, 'custom_attribute');
                                                            }}
                                                            className="mb-2"
                                                            style={{ fontSize: '1.2rem' }} // Larger font size
                                                        >
                                                            <option>Select {attribute.attribute_name}</option>
                                                            {attribute.attributes_values.map(value => (
                                                                <option key={value._id} value={value._id} disabled={!value.isActive}>
                                                                    {value.name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    )}
                                                </Form.Group>
                                            </div>
                                        ))
                                    ) : (
                                        <div>No custom attributes available.</div> // Fallback for no attributes
                                    )}
                                </div>
                                {/* ShopByColor */}
                                <div className="mb-4">
                                    <h4 className="mb-4 text-center"><b>Select Shop-By-Color</b></h4>

                                    {shop_color && shop_color.length > 0 ? (
                                        <div className="d-flex flex-wrap">
                                            {shop_color.map((color) => (
                                                <div key={color._id} className="card me-3 mb-3" style={{ width: '16rem', flex: '1 0 auto' }}>
                                                    <div className="card-body">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={color._id}
                                                            label={color.name}
                                                            onChange={() => handleInputColor(color._id)}
                                                            style={{ fontSize: '1.2rem' }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>No main attributes available.</div>
                                    )}
                                </div>

                                <div className="mb-4">
                                    <h4 className="mb-4 text-center"><b>Select Shop-By-Room</b></h4>

                                    {shop_room && shop_room.length > 0 ? (
                                        <div className="d-flex flex-wrap">
                                            {shop_room.map((room) => (
                                                <div key={room._id} className="card me-3 mb-3" style={{ width: '16rem', flex: '1 0 auto' }}>
                                                    <div className="card-body">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={room._id}
                                                            label={room.name}
                                                            onChange={() => handleInputRoom(room._id)}
                                                            style={{ fontSize: '1.2rem' }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>No main ShopbyRoom available.</div>
                                    )}
                                </div>

                                <div className="mb-4">
                                    <h4 className="mb-4 text-center"><b>Select banner</b></h4>

                                    {banner && banner.length > 0 ? (
                                        <div className="d-flex flex-wrap">
                                            {banner.map((room) => (
                                                <div key={room._id} className="card me-3 mb-3" style={{ width: '16rem', flex: '1 0 auto' }}>
                                                    <div className="card-body">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={room._id}
                                                            label={room.banner_title}
                                                            onChange={() => handleInputBanner(room._id)}
                                                            style={{ fontSize: '1.2rem' }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>No main Banner available.</div>
                                    )}
                                </div>


                                <div className="mb-4">
                                    <h4 className="mb-4 text-center"><b>Select Promocode</b></h4>

                                    {promocode && promocode.length > 0 ? (
                                        <div className="d-flex flex-wrap">
                                            {promocode.map((room) => (
                                                <div key={room._id} className="card me-3 mb-3" style={{ width: '16rem', flex: '1 0 auto' }}>
                                                    <div className="card-body">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={room._id}
                                                            label={room.title}
                                                            onChange={() => handleInputPromocode(room._id)}
                                                            style={{ fontSize: '1.2rem' }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>No main promocode available.</div>
                                    )}
                                </div>
                                <Button type="submit" disabled={isLoading || isButtonDisabled} variant="primary" className="mt-3">
                                    {isLoading ? 'Loading...' : 'Save Attributes'}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </div >
    );
}
