import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { PromocodeContext } from '../../store/PromocodeContextProvider';
import { ToastContainer, toast } from 'react-toastify';

export default function AddPromocode() {


    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { addPromocode } = useContext(PromocodeContext);
    const [formData, setFormData] = useState({
        title: '',
        promocode: '',
        discount_type: '',
        discount_value: '',
        usage_limit: '',
        expiry_date: '',
        max_discounted_amount_upto: '',
        min_amount_to_applicable: '',
        is_applicable_for_all_products: '',
        description: ''
    });

    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validate = () => {
        let tempErrors = {};

        tempErrors.title = formData.title ? "" : "Title is required.";
        tempErrors.promocode = formData.promocode ? "" : "Promocode is required.";
        tempErrors.discount_type = formData.discount_type ? "" : "Discount type is required.";
        tempErrors.discount_value = formData.discount_value ? "" : "Discount value is required.";
        tempErrors.usage_limit = formData.usage_limit ? "" : "Usage limit is required.";
        tempErrors.expiry_date = formData.expiry_date ? "" : "Expiry date is required.";
        tempErrors.description = formData.description ? "" : "Description is required.";
        tempErrors.max_discounted_amount_upto = formData.max_discounted_amount_upto
            ? ""
            : "Max discounted amount is required.";
        tempErrors.min_amount_to_applicable = formData.min_amount_to_applicable
            ? ""
            : "Minimum amount applicable is required.";
        tempErrors.is_applicable_for_all_products = formData.is_applicable_for_all_products
            ? ""
            : "Applicability for all products is required.";

        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    // const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value
    //     });
    // };



    function handleChange(e) {
        var name = e.target.name;
        var value = e.target.value;
        setFormData((oldData) => {
            return {
                ...oldData,
                [name]: value
            }
        });
    }

    const handleSubmit = async (e) => {



        e.preventDefault();
        if (validate()) {


            setIsLoading(true); // Show loader
            setIsButtonDisabled(true); // Disable button

            try {

                var item = {
                    title: formData.title,
                    promocode: formData.promocode,
                    discount_type: formData.discount_type,
                    discount_value: formData.discount_value,
                    usage_limit: formData.usage_limit,
                    expiry_date: formData.expiry_date,
                    max_discounted_amount_upto: formData.max_discounted_amount_upto,
                    min_amount_to_applicable: formData.min_amount_to_applicable,
                    is_applicable_for_all_products: formData.is_applicable_for_all_products,
                    description: formData.description
                }

                // alert(JSON.stringify(item))


                const response = await addPromocode(item);
                console.log('responsen Added', response)



                if (response.result.status == 200) {
                    toast.success('Promocode added successfully!');
                    navigate(`/promocode`);
                }
                else {
                    toast.error('Failed to add Promocode.');
                    setIsLoading(false); // Hide loader
                    setIsButtonDisabled(false); // Enable button
                }
            } catch (error) {
                toast.error('An error occurred while adding the Promocode.');
                setIsLoading(false); // Hide loader
                setIsButtonDisabled(false); // Enable button
            }
        } else {
            toast.error('Please fix the errors in the form.');
            setIsLoading(false); // Hide loader
            setIsButtonDisabled(false); // Enable button
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <ToastContainer />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="page-content fade-in-up">
                        <div className="ibox">
                            <div className="ibox-head">
                                <div className="ibox-title">
                                    <h3>Add New Promocode</h3>
                                </div>
                                <Link to="/promocode" className="btn btn-secondary pull-right">
                                    Back
                                </Link>
                            </div>
                            <div className="ibox-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        {/* Title Field */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Title<span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="title"
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                />
                                                {errors.title && <span className="text-danger">{errors.title}</span>}
                                            </div>
                                        </div>
                                        {/* Promocode Field */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Promocode<span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="promocode"
                                                    value={formData.promocode}
                                                    onChange={handleChange}
                                                />
                                                {errors.promocode && <span className="text-danger">{errors.promocode}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* Discount Type */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Discount Type<span className="text-danger">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="discount_type"
                                                    value={formData.discount_type}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Discount Type</option>
                                                    <option value="percentage">Percentage</option>
                                                    <option value="fixed">Flat</option>
                                                </select>
                                                {errors.discount_type && <span className="text-danger">{errors.discount_type}</span>}
                                            </div>
                                        </div>
                                        {/* Discount Value */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Discount Value<span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="discount_value"
                                                    value={formData.discount_value}
                                                    onChange={handleChange}
                                                />
                                                {errors.discount_value && <span className="text-danger">{errors.discount_value}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* Usage Limit */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Usage Limit<span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="usage_limit"
                                                    value={formData.usage_limit}
                                                    onChange={handleChange}
                                                />
                                                {errors.usage_limit && <span className="text-danger">{errors.usage_limit}</span>}
                                            </div>
                                        </div>
                                        {/* Expiry Date */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Expiry Date<span className="text-danger">*</span></label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="expiry_date"
                                                    value={formData.expiry_date}
                                                    onChange={handleChange}
                                                />
                                                {errors.expiry_date && <span className="text-danger">{errors.expiry_date}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* Max Discounted Amount */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Max Discounted Amount<span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="max_discounted_amount_upto"
                                                    value={formData.max_discounted_amount_upto}
                                                    onChange={handleChange}
                                                />
                                                {errors.max_discounted_amount_upto && (
                                                    <span className="text-danger">{errors.max_discounted_amount_upto}</span>
                                                )}
                                            </div>
                                        </div>
                                        {/* Min Amount Applicable */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Min Amount Applicable<span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="min_amount_to_applicable"
                                                    value={formData.min_amount_to_applicable}
                                                    onChange={handleChange}
                                                />
                                                {errors.min_amount_to_applicable && (
                                                    <span className="text-danger">{errors.min_amount_to_applicable}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* Applicability for All Products */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Is Applicable For All Products<span className="text-danger">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="is_applicable_for_all_products"
                                                    value={formData.is_applicable_for_all_products || ""}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Applicability</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                                {errors.is_applicable_for_all_products && (
                                                    <span className="text-danger">{errors.is_applicable_for_all_products}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* Description */}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Description<span className="text-danger">*</span></label>
                                                <textarea
                                                    className="form-control"
                                                    name="description"
                                                    rows="5"
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                ></textarea>
                                                {errors.description && <span className="text-danger">{errors.description}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Submit Button */}
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary mt-2" disabled={isButtonDisabled}>
                                            {isLoading ? "Adding Promocode..." : "Add Promocode"}
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
