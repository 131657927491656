import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import '../../assets/css/main.min.css';
import Footer from '../../components/Footer';
import { ToastContainer, toast } from 'react-toastify';

const Dashboard = () => {
  useEffect(() => {
    if (localStorage.getItem('isToast')) {
      toast.success('Login successful!', {
        position: 'top-right',
        autoClose: 3000,
      });
      localStorage.removeItem('isToast');
    }
  });

  return (
    <>
      <div className="page-wrapper">
        <Header />
        <ToastContainer />
        <Sidebar />
        <div className="content-wrapper">
          <h1>Admin Dashboard</h1>
          <div className="page-content fade-in-up">
            {/* First Row: Total Revenue, Total Orders, Today's Orders */}
            <div className="row mb-4">
              <div className="col-lg-4 col-md-6">
                <div className="card modern-card bg-primary text-white">
                  <div className="card-body">
                    <h4 className="font-strong mb-2">₹54,300</h4>
                    <span>Total Revenue</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card modern-card bg-success text-white">
                  <div className="card-body">
                    <h4 className="font-strong mb-2">12,430</h4>
                    <span>Total Orders</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card modern-card bg-danger text-white">
                  <div className="card-body">
                    <h4 className="font-strong mb-2">1,230</h4>
                    <span>Today's Orders</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Row: Today's Revenue, Total Customers, Total Products */}
            <div className="row mb-4">
              <div className="col-lg-4 col-md-6">
                <div className="card modern-card bg-warning text-white">
                  <div className="card-body">
                    <h4 className="font-strong mb-2">₹3,200</h4>
                    <span>Today's Revenue</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card modern-card bg-info text-white">
                  <div className="card-body">
                    <h4 className="font-strong mb-2">8,560</h4>
                    <span>Total Customers</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="card modern-card bg-secondary text-white">
                  <div className="card-body">
                    <h4 className="font-strong mb-2">1,240</h4>
                    <span>Total Products</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Recent Orders */}
            <div className="card shadow-sm">
              <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Recent Orders</h5>
                <a href="/orders" className="btn btn-sm btn-light">View All</a>
              </div>
              <div className="card-body p-0">
                <table className="table table-striped table-borderless mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>Order ID</th>
                      <th>Customer</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>#10234</td>
                      <td>John Doe</td>
                      <td>₹240</td>
                      <td><span className="badge badge-success">Completed</span></td>
                      <td>Jan 23, 2025</td>
                      <td>
                        <button className="btn btn-sm btn-outline-primary">Details</button>
                      </td>
                    </tr>
                    <tr>
                      <td>#10235</td>
                      <td>Jane Smith</td>
                      <td>₹120</td>
                      <td><span className="badge badge-warning">Pending</span></td>
                      <td>Jan 23, 2025</td>
                      <td>
                        <button className="btn btn-sm btn-outline-primary">Details</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Top Selling Products */}
            <div className="card mt-4 shadow-sm">
              <div className="card-header bg-success text-white d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Top Selling Products</h5>
                <a href="/product-list" className="btn btn-sm btn-light">View All</a>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div className="card product-card shadow-sm">
                      <div className="card-body text-center">
                        <h6 className="font-weight-bold">Product A</h6>
                        <p className="text-muted">₹4,000</p>
                        <button className="btn btn-sm btn-primary">View Details</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div className="card product-card shadow-sm">
                      <div className="card-body text-center">
                        <h6 className="font-weight-bold">Product B</h6>
                        <p className="text-muted">₹3,500</p>
                        <button className="btn btn-sm btn-primary">View Details</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div className="card product-card shadow-sm">
                      <div className="card-body text-center">
                        <h6 className="font-weight-bold">Product C</h6>
                        <p className="text-muted">₹2,800</p>
                        <button className="btn btn-sm btn-primary">View Details</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
