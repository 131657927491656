import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ListBlog from './ListBlog';
import AddBlog from './AddBlog';
import EditBlog from './EditBlog';


export default function BlogRoutes() {
    return (
        <Routes>
            <Route path='/blogs' element={<ListBlog />} />
            <Route path='/add-blog' element={<AddBlog />} />
            <Route path='/edit-blog/:id' element={<EditBlog />} />
        </Routes>
    );
}
